import type { Translatable } from "@sunrise/i18n";

import { ERROR_MAPPING } from "../error-mapping";
import { getErrorMessage } from "./get-error-message";

export function getErrorMessageAndDescription(
  error: Error,
  generalErrorDisplayContentsFeature: "raw" | "hide" | "general",
): {
  description: Translatable;
  title: Translatable;
} | null {
  switch (generalErrorDisplayContentsFeature) {
    case "raw":
      return {
        description: [getErrorMessage(error) ?? "unknown error"],
        title: error.name,
      };
    case "hide":
      return null;
    case "general":
      return {
        description: ERROR_MAPPING.GENERAL_ERROR.description,
        title: ERROR_MAPPING.GENERAL_ERROR.title,
      };
  }
}
