import { BaseError } from "@sunrise/error";
import type { Nullable } from "@sunrise/utils";

import { ERROR_MAPPING } from "./error-mapping";
import { ERROR_MAPPING_ERROR_TYPES } from "./error-mapping-error-types";
import type { ErrorConfigurationLoader } from "./types";

/**
 * This loads the mapping for the error.
 * It will not inject a generic error mapping.
 * It's up to the caller to do that.
 *
 * @param error
 * @returns
 */
export function getErrorMapping(
  error: Error,
): Nullable<ErrorConfigurationLoader> {
  let errorCode: Nullable<string>;
  let errorType: Nullable<string>;

  if (error instanceof BaseError) {
    errorCode = error.errorCode;
    errorType = error.errorType;
  } else {
    errorCode = null;
    errorType = null;
  }

  // When there is no errorCode it means it is not one of our known errors.
  // And we should not even attempt to find a dialog mapping for it. We will need to let this error pass through.
  if (!errorCode && !errorType) {
    return null;
  }

  let configuration =
    errorCode && errorCode in ERROR_MAPPING
      ? (ERROR_MAPPING[errorCode] as Nullable<ErrorConfigurationLoader>)
      : null;

  // If there is no configuration for the error code, we will try to use a more generic mapping based on the errorType.
  if (!configuration && errorType) {
    configuration = (
      ERROR_MAPPING_ERROR_TYPES as Record<string, ErrorConfigurationLoader>
    )[errorType];
  }

  return configuration;
}
