import { useMemo } from "react";
import { useAtomValue } from "jotai";

import type { TranslateFn } from "@sunrise/i18n";
import { currentLanguageAtom } from "@sunrise/i18n";

import { createTranslator } from "./create-translator";
import { disableTranslationsFeatureAtom } from "./disable-translations.feature.atom";
import { useTranslationContext } from "./translation-provider";

export const useTranslator = (): TranslateFn => {
  const translations = useTranslationContext();
  const language = useAtomValue(currentLanguageAtom);
  const disableTranslations = useAtomValue(disableTranslationsFeatureAtom);

  return useMemo(() => {
    if (disableTranslations) {
      return createTranslator(language, {}, true);
    }

    return createTranslator(language, lowerCaseObject(translations));
  }, [language, translations, disableTranslations]);
};

function lowerCaseObject<T extends Record<string, unknown>>(value: T): T {
  return Object.fromEntries(
    Object.entries(value).map(([key, v]) => [key.toLowerCase(), v]),
  ) as T;
}
