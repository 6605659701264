import type { CriticalPlayerError } from "@sunrise/player";
import type {
  StreamBlockedByBackendError,
  StreamFailedToLoadError,
} from "@sunrise/yallo-stream";

import type { ErrorConfiguration, ErrorConfigurationLoader } from "./types";
import { DialogButtonType } from "./types";

const STREAM_FAILED_TO_LOAD: ErrorConfiguration = {
  title: { key: "streamload_error_title" },
  description: [{ key: "streamload_error_description" }],
  confirmationButton: DialogButtonType.OK,
  technicalErrorName: "streamload_error",
};

const PLAYER_ERROR: ErrorConfiguration = {
  title: { key: "player_error_title" },
  description: [{ key: "player_error_playout_failed" }],
  confirmationButton: DialogButtonType.OK,
  technicalErrorName: "player_error",
};

/**
 * A type where we try to collect all errorTypes. So we can map an error configuration for each error.
 */
type ErrorMappingFallback = Record<
  | typeof StreamBlockedByBackendError.prototype.errorType
  | typeof StreamFailedToLoadError.prototype.errorType
  | typeof CriticalPlayerError.prototype.errorType,
  ErrorConfigurationLoader
>;

export const ERROR_MAPPING_ERROR_TYPES: ErrorMappingFallback = {
  stream_error: STREAM_FAILED_TO_LOAD,
  player: PLAYER_ERROR,
};
