import { atom } from "jotai";
import { selectAtom } from "jotai/utils";
import { withAtomEffect } from "jotai-effect";

import { loginIdTokenEffect } from "./effects/login-id-token.effect";

export const PROCESSED = Symbol("processed");

const _idTokenAtom = atom<string | typeof PROCESSED | null>(null);
/**
 * When set it means that we are planning to log in with the id_token.
 * It'll automatically be unset when the login is done.
 */
export const idTokenAtom = withAtomEffect(_idTokenAtom, (get) => {
  get(loginIdTokenEffect);
});

export const selectIsAuthenticationIdTokenProcessed = selectAtom(
  idTokenAtom,
  (data) => data === PROCESSED,
);
