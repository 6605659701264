import type { Atom } from "jotai";
import { atom } from "jotai";
import { loadable } from "jotai/utils";
import { withAtomEffect } from "jotai-effect";

/**
 * Creates a stable atom that maintains its last successful value even when the source atom is loading.
 * No longer exposes the loadable properties.
 *
 * @param source The source atom to stabilize
 * @returns A new atom that maintains the last successful value
 */
export const stableLoadable = <T>(source: Atom<T>): Atom<Awaited<T | null>> => {
  const innerValue = atom<Awaited<T> | null>(null);

  const load = loadable(source);

  return withAtomEffect(innerValue, (get, set) => {
    const value = get(load);

    if (value.state === "hasData") {
      set(innerValue, value.data);
    }
  });
};
