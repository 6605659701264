// @ts-expect-error: shaka types needed for dealWithError function
import shakaPlayer from "shaka-player/dist/shaka-player.compiled";

import { BaseError } from "@sunrise/error";
import type { Nullable } from "@sunrise/utils";
import type { PlayRequest } from "@sunrise/yallo-player-types";

import { isShakaError } from "../utils/is-shaka-error";

/**
 * Only used for errors that are caused by the player.
 */
export class PlayerError extends BaseError {
  constructor(
    e: unknown,
    playRequest?: Nullable<PlayRequest>,
    streamUrl?: Nullable<string>,
  ) {
    if (isShakaError(e)) {
      super("ShakaPlayerError", e.message, {
        errorCode: e.code.toString(),
        cause: e,
        extras: {
          data: e.data,
          categoryName:
            CATEGORY_MAPPING[e.category as keyof typeof CATEGORY_MAPPING],
          playRequest,
          streamUrl,
        },
      });
    } else {
      super(
        "UnknownPlayerError",
        e instanceof Error ? e.message : "unknown-player-error",
        { cause: e, extras: { playRequest, streamUrl } },
      );
    }
  }

  override get shouldRetry(): boolean {
    return false;
  }

  get shouldAutoRecover(): boolean {
    return false;
  }
}

/**
 * Category refers to https://shaka-player-demo.appspot.com/docs/api/shaka.util.Error.html#.Category
 *
 * Name	      Value	 Description
 * NETWORK    1      Errors from the network stack.
 * TEXT       2      Errors parsing text streams.
 * MEDIA      3      Errors parsing or processing audio or video streams.
 * MANIFEST   4      Errors parsing the Manifest.
 * STREAMING  5      Errors related to streaming.
 * DRM	      6      Errors related to DRM.
 * PLAYER     7      Miscellaneous errors from the player.
 * CAST       8      Errors related to cast.
 * STORAGE    9      Errors in the database storage (offline).
 * ADS        10     Errors related to ad insertion.
 */
const CATEGORY_MAPPING = {
  [shakaPlayer.util.Error.Category.NETWORK]: "network",
  [shakaPlayer.util.Error.Category.TEXT]: "text",
  [shakaPlayer.util.Error.Category.MEDIA]: "media",
  [shakaPlayer.util.Error.Category.MANIFEST]: "manifest",
  [shakaPlayer.util.Error.Category.STREAMING]: "streaming",
  [shakaPlayer.util.Error.Category.DRM]: "drm",
  [shakaPlayer.util.Error.Category.PLAYER]: "player",
  [shakaPlayer.util.Error.Category.CAST]: "cast",
  [shakaPlayer.util.Error.Category.STORAGE]: "storage",
  [shakaPlayer.util.Error.Category.ADS]: "ads",
};
