import {
  type EmptyAdEventSchema,
  type EndAdEventSchema,
  type ErrorAdEventSchema,
  type GeneratedApi,
  type SkippedAdEventSchema,
  type StartAdEventSchema,
} from "@sunrise/backend-ng-events";

export async function postAdEvents(
  api: GeneratedApi,
  data:
    | StartAdEventSchema
    | EndAdEventSchema
    | EmptyAdEventSchema
    | SkippedAdEventSchema
    | ErrorAdEventSchema,
  /**
   * When something is suspicious, we want to let the backend know by injecting an x-tracked header.
   */
  isSuspicious: boolean = false,
) {
  return api.event.postEventEventV1EventsPost(
    data,
    isSuspicious ? { headers: { "x-tracked": "true" } } : {},
  );
}
