import { atom } from "jotai";
import { atomWithSuspenseQuery } from "jotai-tanstack-query";
import { isNil } from "lodash";

import { endpoints, type SettingsV2 } from "@sunrise/backend-types";
import { hostsAtom, publicApi } from "@sunrise/http-client";

import { platformAtom, settingsV2VersionAtom } from "./settings.store";

const settingsQueryAtom = atomWithSuspenseQuery<SettingsV2>((get) => ({
  queryKey: ["settings-v2"],

  queryFn: async () => {
    const host = get(hostsAtom).clients;
    const platform = get(platformAtom);
    const version = get(settingsV2VersionAtom);

    if (isNil(host)) throw new Error("Host is not set");
    if (isNil(platform)) throw new Error("Platform is not set");
    if (isNil(version)) throw new Error("Settings version is not set");

    return (
      await publicApi.get<SettingsV2>(
        endpoints.settings_v2(host, platform, version),
      )
    ).data;
  },
  staleTime: Infinity,
  cacheTime: Infinity,
}));

export const settingsV2Atom = atom(async (get) => {
  return (await get(settingsQueryAtom)).data;
});
