import type { VideoAdScreenSize } from "@sunrise/backend-types";
import { type Store } from "@sunrise/store";

import { getAdCustomClickTrackingElement } from "./get-ad-custom-clicktracking-element";
import { getVideoAdsElement } from "./get-video-ads-element";
import { PauseAds } from "./pause-ads";
import { VideoAds } from "./video-ads";

let videoAdsInternal: VideoAds | undefined;
let pauseAdsInternal: PauseAds | undefined;

export const setAdVolume = (volume: number): void => {
  return videoAdsInternal?.setVolume(volume);
};
export const getAdVolume = (): number => {
  return videoAdsInternal?.getVolume() ?? 0;
};

export function skipAd(): void {
  return videoAdsInternal?.manualSkip();
}

export function resumeAds(): void {
  return videoAdsInternal?.resume();
}

export function initVideoAds(
  store: Store,
  getVideoElement: () => HTMLVideoElement,
  showPlayer: () => void,
  allowSkippingAds = false,
  screenSize?: VideoAdScreenSize,
): VideoAds {
  videoAdsInternal = new VideoAds(
    store,
    getVideoElement(),
    getVideoAdsElement(),
    getAdCustomClickTrackingElement(),
    showPlayer,
    screenSize,
  );

  // allow skipping ads w/ a keyboard shortcut
  if (allowSkippingAds) {
    window.addEventListener("keypress", (e) => {
      if (e.defaultPrevented) {
        return;
      }

      if (e.key === "s") {
        videoAdsInternal?.manualSkip();
      }
    });
  }

  return videoAdsInternal;
}

export function initPauseAds(store: Store): PauseAds {
  pauseAdsInternal = new PauseAds(store);
  return pauseAdsInternal;
}

export const triggerNewPauseAd = (): void => {
  pauseAdsInternal?.triggerNewAd();
};
