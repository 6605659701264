import type { Atom } from "jotai";
import { atom } from "jotai";
import { atomFamily } from "jotai/utils";

import { isLegacyBackendAtom } from "@sunrise/backend-core";
import { recordingStatusByEpgEntryIdQueryAtom } from "@sunrise/backend-ng-recordings";
import type { RecordingStatusUpdate } from "@sunrise/backend-types";
import type { EPGEntryId, RecordingId } from "@sunrise/backend-types-core";
import { selectCanRecord } from "@sunrise/jwt";
import type { Nullable } from "@sunrise/utils";

import { recordingStatusLegacyAtom } from "./legacy/recording-status.legacy.atom";
import { recordingStatusSocketAtom } from "./recording-status-socket.atom";
import type { SimplifiedRecordingStatus } from "./types";
import { recordingStatusToSimplifiedRecording } from "./utils/recording-status-to-simplified-recording";

/**
 * This returns the RecordingStatus for a specific EPGEntryId.
 * The RecordingStatus just indicates if there is a recording and if so if it is planned or recorded.
 *
 * We will also return the associated recordingId when we find a recording.
 */
export const recordingStatusByEpgIdAtom = atomFamily<
  EPGEntryId,
  Atom<
    Promise<
      Nullable<{
        status: SimplifiedRecordingStatus;
        recordingId: RecordingId;
      }>
    >
  >
>((epgId: EPGEntryId) => {
  const innerAtom = atom(async (get) => {
    if (get(isLegacyBackendAtom)) {
      const data = await get(recordingStatusLegacyAtom);

      const item = data.find((status) => status.epgId === epgId);

      if (!item) {
        return null;
      }

      return socketStateToSimplifiedRecordingStatus(item);
    } else {
      // If a user is not able to record we will never have a recording status so we can skip the entire code
      if (!get(selectCanRecord)) return null;

      // First we look if we have a status directly by epgEntryId.
      // If we do we don't need to ask for the status in the API. We can assume that data on this level is always up to date.
      const socketStates = get(recordingStatusSocketAtom);
      const socketStateByEpgEntryId = socketStates.find(
        (status) => status.epgId === epgId,
      );

      const statusForEpgEntryId =
        socketStateByEpgEntryId &&
        socketStateToSimplifiedRecordingStatus(socketStateByEpgEntryId);

      // When we have a status or we know for sure that it has been removed we return that.
      if (statusForEpgEntryId || statusForEpgEntryId === null) {
        return statusForEpgEntryId;
      }
      // If we don't have a socket status yet on epgEntryId we need to fetch the status from the API.
      // When we get it from the API we can't assume that it'll be the latest state.
      // We should not assume the latest status is returned from the API.
      // However, the latest status is handled above when we receive a socket update for the epgEntryId.
      // There is no world in which listening to the socket for the recordingId would return a more up to date result.
      // It should be the exact same record since the backend still knows about the epgEntryId associated with the recordingId.
      const query = await get(recordingStatusByEpgEntryIdQueryAtom(epgId));

      if (!query.data) {
        return null;
      }

      // When we don't have a socket status for the recordingId we can assume that the status from the API is the latest information we know.
      const status: SimplifiedRecordingStatus | null =
        recordingStatusToSimplifiedRecording(query.data.status);

      if (!status) {
        return null;
      }

      return {
        status,
        recordingId: query.data.recordingId,
      };
    }
  });
  innerAtom.debugLabel = `recordingStatusByEpgIdAtom(${epgId})`;
  return innerAtom;
});

/**
 * @returns
 *  null means that we know for sure there is no recording.
 *  undefined means that we don't know for sure what the status maps to.
 */
function socketStateToSimplifiedRecordingStatus(
  state: RecordingStatusUpdate,
): Nullable<{
  status: SimplifiedRecordingStatus;
  recordingId: RecordingId;
}> {
  switch (state.status) {
    case "planned":
      return {
        status: "planned",
        recordingId: state.recordingId,
      };
    case "recorded":
      return {
        status: "recorded",
        recordingId: state.recordingId,
      };
    case "deleted":
    case "canceled":
    case "cancelled":
      return null;
    default:
      return;
  }
}
