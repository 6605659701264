import { lazy, type ReactElement, type ReactNode } from "react";
import type { createStore } from "jotai";
import { Provider } from "jotai";

const LazyDevTools = lazy(() => import("./lazy-devtools"));

export type Store = ReturnType<typeof createStore>;

type StoreProps = {
  children: ReactNode;
  enableDevTools?: boolean;
  store?: Store;
};

export function StoreProvider({
  children,
  enableDevTools = false,
  store,
}: StoreProps): ReactElement {
  return (
    <Provider store={store}>
      {enableDevTools ? <LazyDevTools store={store} /> : null}
      {children}
    </Provider>
  );
}
