import { featureAtom } from "@sunrise/feature-flags";

/**
 * Controls to show either the full content of the error or just a general error message.
 *
 * By default in development and testing we will show the full content of the error.
 * In production we will only show a general error message.
 *
 * We can also modify it so in production we fully hide the errors.
 */
export const generalErrorDisplayContentsFeatureAtom = featureAtom<
  "raw" | "general" | "hide"
>(
  "general-error-display-contents",
  process.env["NODE_ENV"] === "development" ? "raw" : "general",
  {
    values: ["raw", "general", "hide"],
    description:
      "Controls to show either the full content of the general error or just a general error message. Can also hide the error completely.",
  },
);
