import { atomFamily } from "jotai/utils";
import { atomWithSuspenseQuery } from "jotai-tanstack-query";

import { validate400and404status } from "@sunrise/backend-ng-core";
import { ngRecordingsApiAtom } from "@sunrise/backend-ng-recordings";
import { queryKeys } from "@sunrise/backend-types";
import type {
  RecordingGroupId,
  RecordingId,
} from "@sunrise/backend-types-core";
import { currentLanguageAtom } from "@sunrise/i18n";
import { selectJwtUserToken } from "@sunrise/jwt";

export const recordingByIdNgAtom = atomFamily(
  (recordingId: RecordingId | RecordingGroupId) => {
    const innerAtom = atomWithSuspenseQuery((get) => {
      const ngApi = get(ngRecordingsApiAtom);
      const language = get(currentLanguageAtom);

      return {
        queryKey: queryKeys.recordingByRecordingIdNg(
          get(selectJwtUserToken),
          recordingId,
          language,
        ),
        queryFn: async () => {
          if (!recordingId) return null;
          return (
            await ngApi.recording.getRecordingRecordingV1RecordingsRecordingIdGet(
              recordingId,
              {
                validateStatus: validate400and404status,
              },
            )
          ).data;
        },
        staleTime: Infinity,
        cacheTime: Infinity,
      };
    });

    innerAtom.debugLabel = `recordingByIdAtom(${recordingId})`;

    return innerAtom;
  },
);
