import { atom } from "jotai";
import { atomEffect } from "jotai-effect";

import { isLegacyBackendAtom } from "@sunrise/backend-core";
import type { ContinueWatchingStatusSchema } from "@sunrise/backend-ng-continue-watching";
import type {
  ContinueWatchingId,
  EPGEntryId,
  RecordingGroupId,
  RecordingId,
} from "@sunrise/backend-types-core";
import { selectWsToken } from "@sunrise/jwt";
import type { Nullable } from "@sunrise/utils";

import { socketConnectedAtom } from "./socket-connected.atom";
import { socketUrlAtom } from "./socket-url.atom";

// 1011 is the code for "keepalive ping timeout" or "internal error".
// 1006 is the code for "abnormal closure".
// 1012 is the code for "service restart".
// 1013 is the code for "try again later".
// 1014 is the code for "bad gateway".
const SOCKET_ERROR_CODES_THAT_TRIGGER_RECONNECT = [
  1011, 1006, 1012, 1013, 1014,
];

type RecordingStatusUpdate = {
  object_id: RecordingId;
  event_type: "recording_created" | "recording_updated" | "recording_deleted";
  object_type: "recording";
  payload: {
    id: RecordingId;
    status: "recorded" | "planned" | "deleted";
    epg_entry_id?: Nullable<EPGEntryId>;
  };
};

/**
 * Currently not in use yet.
 */
type RecordingGroupStatusUpdate = {
  object_id: RecordingGroupId;
  event_type:
    | "recording_group_created"
    | "recording_group_updated"
    | "recording_group_deleted";
  object_type: "recording_group";
  payload: {
    id: RecordingGroupId;
    status: "mixed" | "all_recorded" | "all_planned";
  };
};

type ContinueWatchingPayload = ContinueWatchingStatusSchema & {
  epg_entry_id: EPGEntryId;
  recording_id?: RecordingId | null;
};

type ContinueWatchingCreated = {
  object_id: ContinueWatchingId;
  event_type: "created";
  payload: ContinueWatchingPayload;
};

type ContinueWatchingUpdated = {
  object_id: ContinueWatchingId;
  event_type: "updated";
  payload: ContinueWatchingPayload;
};

export type SocketMessages = {
  ["recordings"]: {
    payload: RecordingStatusUpdate | RecordingGroupStatusUpdate;
  };
  ["continue_watching"]: {
    payload: ContinueWatchingCreated | ContinueWatchingUpdated;
  };
};

/**
 * Just a very basic websocket instance for internal use.
 * Will be set whenever the socketUrlAtom or selectWsToken or socketConnectionCountAtom changes.
 */
const _socketAtomInternal = atom<WebSocket | null>(null);

function buildSocket(url: string, token: string): WebSocket {
  const u = new URL(`${url}/event/v1/websocket`);
  u.searchParams.set("authorization", token);
  return new WebSocket(u.href);
}

_socketAtomInternal.debugPrivate = true;

/**
 * Will return an layer over the socket on which consumers can register listeners.
 *
 * It will only start up when there's a valid JWT websocket token.
 */
export const socketAtom = atom((get) => {
  const socket = get(_socketAtomInternal);

  get(trackReconnectSocketOnErrorEffect);

  if (!socket) return null;

  return {
    /**
     * Allows registering a listener for a specific namespace.
     *
     * The listener will be required to filter out further messages in this namespace.
     */
    on<K extends keyof SocketMessages>(
      namespace: K,
      cb: (data: SocketMessages[K]["payload"]) => void,
    ) {
      const listener = (event: MessageEvent) => {
        const data = JSON.parse(event.data);
        if (data.namespace === namespace) {
          cb(data);
        }
      };
      socket.addEventListener("message", listener);

      return () => {
        socket.removeEventListener("message", listener);
      };
    },
  };
});

/**
 * Socket should rebuild itself whenever this number increases.
 */
const _socketConnectionCountAtom = atom(0);
_socketConnectionCountAtom.debugPrivate = true;
/**
 * When the socket errors, we should reconnect it automatically.
 * We do the reconnect with a delay that increases with each error.
 * To a maximum of 1 minute.
 *
 * The reset happens by setting the socketConnectionCountAtom.
 * Which is something the socket creation depends on and so it will
 * recreate itself because of the the updated count.
 */
const trackReconnectSocketOnErrorEffect = atomEffect((get, set) => {
  const url = get(socketUrlAtom);
  const token = get(selectWsToken);

  if (get(isLegacyBackendAtom)) {
    set(_socketAtomInternal, null);
    return;
  }

  if (!url || !token) {
    if (process.env["NODE_ENV"] === "development") {
      // eslint-disable-next-line no-console
      console.warn("ng socket needs a reauthentication before it can start");
    }
    set(_socketAtomInternal, null);
    return;
  }

  let shouldBeActive = true;

  get(_socketConnectionCountAtom);
  let timeoutForReconnect: ReturnType<typeof setTimeout> | null = null;

  const socket = buildSocket(url, token);
  set(_socketAtomInternal, socket);

  socket.addEventListener("open", function () {
    if (!shouldBeActive) return;

    set(socketConnectedAtom, true);
  });

  /**
   * Random number between 1 and 5 so some clients have slower reconnects.
   */
  const backoffMultiplier = 1000 + Math.random() * 4000;
  const handleReconnectWithBackoff = () => {
    if (timeoutForReconnect) {
      clearTimeout(timeoutForReconnect);
    }
    const count = get(_socketConnectionCountAtom);
    // Spread reconnects over 3m.
    const delay = Math.min(count + 1, 180);
    timeoutForReconnect = setTimeout(() => {
      set.recurse(_socketConnectionCountAtom, count + 1);
    }, delay * backoffMultiplier);
  };

  socket.addEventListener("close", function (event) {
    if (!shouldBeActive) return;

    set(socketConnectedAtom, false);

    // It is possible we get a close event which should actually be an error event.
    // In that case we should increase the reconnect counter as well.
    if (
      // When it was not a clean close, we should increase the reconnect counter so we re-trigger the reconnect logic.
      !event.wasClean ||
      // It's possible we get a clean close event with an error code that still indicates a problem.
      SOCKET_ERROR_CODES_THAT_TRIGGER_RECONNECT.includes(event.code)
    ) {
      handleReconnectWithBackoff();
    }
  });

  socket.addEventListener("error", () => {
    handleReconnectWithBackoff();
  });

  return () => {
    shouldBeActive = false;

    set(socketConnectedAtom, false);
    if (timeoutForReconnect) {
      clearTimeout(timeoutForReconnect);
    }

    // Make sure to reset the socket atom so it will be fully recreated on reconnect.
    set(_socketAtomInternal, null);

    socket.close();
  };
});
