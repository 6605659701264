import type { StreamErrorCode } from "@sunrise/backend-types";
import { BaseError } from "@sunrise/error";

const NON_TRACED_ERROR_CODES: StreamErrorCode[] = [
  "E_REPLAY_NOT_ACTIVATED",
  "E_REPLAY_NOT_ACTIVATED_LONG_ENOUGH",
] as const;

export class StreamBlockedByBackendError extends BaseError {
  constructor(readonly code: StreamErrorCode) {
    super(`StreamBlockedByBackendError`, code, { errorCode: code });
  }

  override get shouldTrace(): boolean {
    if (!this.errorCode) return true;
    return !NON_TRACED_ERROR_CODES.includes(this.errorCode as StreamErrorCode);
  }

  get shouldAutoRecover(): boolean {
    return false;
  }

  override get errorType(): "stream_error" {
    return "stream_error";
  }
}
