import { atom } from "jotai";

import { createDateToCurrentTimeConverter } from "@sunrise/yallo-stream";

import {
  selectPlayerCurrentStream,
  selectPlayerCurrentStreamModel,
} from "./selectors";

/**
 * A utility atom which will return a DateToNumberConverter for the current stream.
 * This is only relevant for linear streams as only linear streams need to have a mapping between date and player position.
 *
 * The converter allows to do conversion both ways. From player number to date and from date to player number.
 */
export const playerDateTimeConverterAtom = atom((get) => {
  const model = get(selectPlayerCurrentStreamModel);
  const stream = get(selectPlayerCurrentStream);
  if (!stream) return null;

  if (model === "linear") {
    return createDateToCurrentTimeConverter(
      "offset" in stream ? stream.offset : null,
    );
  }

  if (model === "recording") {
    return createDateToCurrentTimeConverter(
      "linearStartTime" in stream ? stream.linearStartTime.getTime() : null,
    );
  }

  return null;
});
