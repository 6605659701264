import type { ValueOf } from "ts-essentials";

import type { ChannelId } from "@sunrise/backend-types-core";
import { type Nullable } from "@sunrise/utils";

type PreflightChecks = {
  error_code: null | number | string;
  skipped: boolean;
  additional_info: {
    whitelisted: boolean;
  };
  passed: boolean;
  name: string;
};

/**
 * The StreamModel is important to know how seeking and the progressbar should behave in the player.
 * For linear streams everything will need to be mapped to dates.
 * While for recording streams we can just uses the values as reported by the player.
 */
export type StreamModel = "linear" | "recording" | "on-demand";

/**
 * For linear streams, the player works with numbers.
 * Depending on the type of stream and the model of the stream, the same number can mean a different date.
 */
export type DateToNumberConverter = {
  fromDate: (date: Date) => number;
  toDate: (number: number) => Nullable<Date>;
};

type StreamAdBreak = {
  /**
   * "Beginning of channel promotion."
   * "Only present if channel promotion is signaled by the broadcaster."
   */
  promo_start?: number | null;
  /**
   * "Beginning of the ad break."
   */
  start: number;
  /**
   * "End of channel promotion (or ad break if channel promotion is missing)."
   * "If no channel promotion is signaled use this."
   * "Only present if channel promotion is signaled by the broadcaster."
   */
  end: number;
  /**
   * "End of ad break, optional."
   * "If channel promotion is signaled by the broadcaster use this."
   */
  ads_end?: number | null;
};

/**
 * https://entwicklungspark.atlassian.net/wiki/spaces/WT/pages/3230334983/Extract+of+Zattoo+API#Alternative-Ads-(aka.-GT-12)
 *
 * All of this documentation in double quotes is from the backend documentation which is ultra vague.
 */
export type StreamAdMarker = {
  /**
   * "Beginning of the program."
   */
  program_start: number;
  ad_breaks?: StreamAdBreak[] | null;
};

/**
 * Once the legacy streams are fully replaced by NG streams, this type can be reduced.
 */
export type StreamErrorCode =
  // Legacy. Although not sure if some NG errors re-use the same codes.
  | "CHANNELPACKAGECHECK.CHANNEL_NOT_IN_ANY_PACKAGES"
  | "GEOCHECK.USER_ABROAD"
  | "REPLAYACTIVATIONCHECK.OUT_OF_BOUNDS"
  | "REPLAYACTIVATIONCHECK.REPLAY_NOT_ACTIVATED"
  | "REPLAYACTIVATIONCHECK.NOT_ACTIVATED_LONG_ENOUGH"
  | "MULTISTREAMCHECK.TOO_MANY_STREAMS"

  // NG
  | "E_IS_ABROAD"
  | "E_NOT_ENOUGH_ADS_WATCHED"
  | "E_START_NOT_IN_TIME_WINDOW"
  | "E_REPLAY_NOT_ACTIVATED"
  | "E_REPLAY_NOT_ACTIVATED_LONG_ENOUGH";

export type StreamResponseLegacy = {
  start: string;
  stream_type: StreamType;
  picture_url: null | string;
  _preflight_checks: PreflightChecks[];
  channel_name: string;
  license_url: null | string;
  url: string;
  provider_channel_id: string;
  provider: string;
  timepoint: string;
  markers?: StreamAdMarker[];
};

export type RecordingStreamResponseLegacy = {
  checks_results: {
    additional_info: Nullable<string>;
    error_code: Nullable<string>;
    name: string;
    passed: boolean;
    skipped: boolean;
  }[];
  download_resolution: string;
  download_url: string;
  license_url: string;
  padding_end_minutes: Nullable<string>;
  padding_start_minutes: Nullable<string>;
  play_url: string;
  provider_channel_id: ChannelId;
  provider: string;
  provider_id: string;
  start: string;
  stop: string;
  stream_type: StreamType;
  timepoint: Nullable<string>;
};

export type Stream = SimpleStream | ReplayStream | RecordingStream;

/**
 * This covers the case for live and recordings. Since these streams do not have an offset.
 */
export type SimpleStream = {
  url: string;
  /**
   * The type of stream can technically differ per stream. Some streams may have no DRM. Others might.
   * So it is part of the stream object. Even though the value comes from the JWT on NG.
   */
  type: Nullable<StreamType>;
  licenseUrl: Nullable<string>;
  provider: "zattoo" | "greenstreams" | string;
};

export type ReplayStream = {
  /**
   * The offset for replay is expressed as milliseconds since epoch.
   *
   * This is the offset for the stream. For replay streams this will be the time that happens before the stream starts.
   * That means the position in the player will start from 0 and be counting up.
   * For a live stream the offset will not be present.
   */
  offset: number;
  linearStartTime: Date;
  markers?: StreamAdMarker[];
} & SimpleStream;

export type RecordingStream = {
  linearStartTime: Date;
  markers?: StreamAdMarker[];
} & SimpleStream;

export const VALID_STREAM_TYPES = [
  "dash",
  "dash_widevine",
  "hls7",
  "hls7_fairplay",
  "mp4",
] as const;

export type StreamType = ValueOf<typeof VALID_STREAM_TYPES>;
