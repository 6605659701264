import { type Atom } from "jotai";
import { atomWithReducer, selectAtom } from "jotai/utils";
import { isNil } from "lodash";

import type { VideoAdConfig, VideoAdTag } from "@sunrise/backend-types";
import { type Nullable } from "@sunrise/utils";

import { isFFwdAdTag } from "./helpers/is-ffwd-ad-tag";
import { isReplayAdTag } from "./helpers/is-replay-ad-tag";

type AdPod = {
  position: number;
  totalAds: number;
};

export type VideoAdsAtomState = {
  adConfig: Nullable<VideoAdConfig>;
  currentAdIndex: Nullable<number>;
  pod: Nullable<AdPod>;
  isPlaying: boolean;
  /**
   * On mobile IMA does not support default click tracking, meaning that the URL in the clickThrough property won't be added to the player.
   * We provide a custom element that gets the event from ima but we need to conditionally show/hide this element when applicable.
   */
  isUsingCustomClickTracking: boolean;
  duration: Nullable<number>;
  currentAdRemainingTime: Nullable<number>;
  adsDurations: number[];
  isPaused: boolean;
  /**
   * When this is set, the native skip button should be shown in X amount of seconds.
   * Also assume there will be a 5 second countdown before the skip button is shown.
   * So at some point, the ad controls in the bottom right need to be shifted a bit. And any native skip button should be hidden.
   */
  skipTimeOffset: number | null;
  /**
   * When set to true we know for sure that it is showing the native skip button.
   */
  nativeSkipControlState: boolean;
};

type ActionSetAdConfig = {
  type: "video-ads/set-ad-config";
  payload: {
    config: VideoAdConfig;
  };
};

type ActionNextAdTag = {
  type: "video-ads/set-next-ad";
};

type ActionSetPod = {
  type: "video-ads/set-ad-pod";
  payload: AdPod;
};

type ActionSetAdPlaying = {
  type: "video-ads/set-ad-playing";
  payload: {
    isPlaying: boolean;
    isUsingCustomClickTracking: boolean;
  };
};

type ActionSetAdPaused = {
  type: "video-ads/set-ad-paused";
  payload: {
    isPaused: boolean;
  };
};

type ActionSetAdDuration = {
  type: "video-ads/set-ad-duration";
  payload: {
    duration: Nullable<number>;
  };
};

type ActionSetCurrentAdRemainingTime = {
  type: "video-ads/set-current-ad-remaining-time";
  payload: {
    currentAdRemainingTime: Nullable<number>;
  };
};

type ActionSetSingleAdStarted = {
  type: "video-ads/set-single-ad-started";
  payload: {
    adDuration: number;
    skipTimeOffset: number | null;
  };
};

type ActionSetNativeSkipControlState = {
  type: "video-ads/set-native-skip-control-state";
  payload: {
    state: boolean;
  };
};
export type VideoAdsAction =
  | ActionSetAdConfig
  | ActionNextAdTag
  | ActionSetAdPlaying
  | ActionSetAdDuration
  | ActionSetCurrentAdRemainingTime
  | ActionSetPod
  | ActionSetSingleAdStarted
  | ActionSetAdPaused
  | ActionSetNativeSkipControlState;

const DEFAULT_STATE: VideoAdsAtomState = {
  adConfig: null,
  pod: null,
  currentAdIndex: null,
  isPlaying: false,
  isUsingCustomClickTracking: false,
  duration: null,
  currentAdRemainingTime: null,
  adsDurations: [],
  isPaused: false,
  skipTimeOffset: null,
  nativeSkipControlState: false,
};

function reducer(
  current: VideoAdsAtomState,
  action: VideoAdsAction,
): VideoAdsAtomState {
  switch (action.type) {
    case "video-ads/set-ad-config":
      return {
        ...current,
        adConfig: action.payload.config,
        currentAdIndex: 0,
      };
    case "video-ads/set-next-ad": {
      const { adConfig, currentAdIndex } = current;
      if (
        isNil(currentAdIndex) ||
        !adConfig ||
        isNil(adConfig.tags[currentAdIndex + 1])
      ) {
        return DEFAULT_STATE;
      }

      return {
        ...current,
        pod: null,
        currentAdIndex: currentAdIndex + 1,
        duration: DEFAULT_STATE.duration,
        nativeSkipControlState: false,
      };
    }
    case "video-ads/set-ad-playing":
      return {
        ...current,
        isPlaying: action.payload.isPlaying,
        isUsingCustomClickTracking: action.payload.isUsingCustomClickTracking,
      };

    case "video-ads/set-ad-duration":
      return {
        ...current,
        duration: action.payload.duration,
      };

    case "video-ads/set-current-ad-remaining-time":
      return {
        ...current,
        currentAdRemainingTime:
          action.payload.currentAdRemainingTime &&
          action.payload.currentAdRemainingTime > 0
            ? action.payload.currentAdRemainingTime
            : null,
      };

    case "video-ads/set-single-ad-started":
      return {
        ...current,
        adsDurations: [...current.adsDurations, action.payload.adDuration],
        skipTimeOffset: action.payload.skipTimeOffset,
        nativeSkipControlState: false,
      };
    case "video-ads/set-ad-pod": {
      return {
        ...current,
        pod: action.payload,
      };
    }
    case "video-ads/set-ad-paused": {
      return {
        ...current,
        isPaused: action.payload.isPaused,
      };
    }
    case "video-ads/set-native-skip-control-state": {
      return {
        ...current,
        nativeSkipControlState: action.payload.state,
      };
    }
  }
}

export function actionPlayerSetAdConfig(
  config: VideoAdConfig,
): ActionSetAdConfig {
  return {
    type: "video-ads/set-ad-config",
    payload: { config },
  };
}

export function actionSetNextAd(): ActionNextAdTag {
  return {
    type: "video-ads/set-next-ad",
  };
}

export function actionSetAdPod(pod: AdPod): ActionSetPod {
  return {
    type: "video-ads/set-ad-pod",
    payload: pod,
  };
}

export function actionSetAdsPlaying(
  isPlaying = true,
  isUsingCustomClickTracking = false,
): ActionSetAdPlaying {
  return {
    type: "video-ads/set-ad-playing",
    payload: { isPlaying, isUsingCustomClickTracking },
  };
}

export function actionSetAdDuration(
  duration: Nullable<number>,
): ActionSetAdDuration {
  return {
    type: "video-ads/set-ad-duration",
    payload: { duration },
  };
}

export function actionSetCurrentAdRemainingTime(
  currentAdRemainingTime: Nullable<number>,
): ActionSetCurrentAdRemainingTime {
  return {
    type: "video-ads/set-current-ad-remaining-time",
    payload: { currentAdRemainingTime },
  };
}

export function actionSingleAdStarted(
  adDuration: number,
  skipTimeOffset: number | null,
): ActionSetSingleAdStarted {
  return {
    type: "video-ads/set-single-ad-started",
    payload: { adDuration, skipTimeOffset },
  };
}

export function actionSetAdPaused(isPaused: boolean): ActionSetAdPaused {
  return {
    type: "video-ads/set-ad-paused",
    payload: { isPaused },
  };
}

export function actionSetNativeSkipControlState(
  state: boolean,
): ActionSetNativeSkipControlState {
  return {
    type: "video-ads/set-native-skip-control-state",
    payload: { state },
  };
}

export const videoAdsAtom = atomWithReducer<VideoAdsAtomState, VideoAdsAction>(
  DEFAULT_STATE,
  reducer,
);

// debug label has to be defined because the atom is encapsulated in a factory
videoAdsAtom.debugLabel = "videoAdsAtom";

export function selectCurrentVideoAdTag(
  atom = videoAdsAtom,
): Atom<Nullable<VideoAdTag>> {
  const slice = selectAtom(atom, (state) => {
    const { adConfig, currentAdIndex } = state;
    if (!adConfig || isNil(currentAdIndex)) return null;

    return adConfig.tags[currentAdIndex] ?? null;
  });

  slice.debugLabel = selectCurrentVideoAdTag.name;

  return slice;
}

/**
 * The player may need to stop acting on certain commands if we should start to play ads. This does not mean we are already playing ads.
 */
export function selectShouldVideoAdBePlaying(
  atom = videoAdsAtom,
): Atom<boolean> {
  const slice = selectAtom(atom, (state) => {
    const { adConfig, currentAdIndex } = state;
    return !!(adConfig && !isNil(currentAdIndex));
  });

  slice.debugLabel = selectShouldVideoAdBePlaying.name;

  return slice;
}

/**
 * We need to know when we are playing ads with 100% certainty. So we know when to hide the player.
 */
export const areVideoAdsPlayingAtom = selectAtom(
  videoAdsAtom,
  (state) => state.isPlaying,
);

/**
 * When a user clicks on the ad it pauses automatically, we want to show a resume button to continue the ad.
 * This might also be helpful on mobile or if autoplay is blocked by the browser
 */
export const areVideoAdsPausedAtom = selectAtom(
  videoAdsAtom,
  (state) => state.isPaused,
);

/**
 * Indicates of IMA is providing the element that handles when the ad is clicked or if we provide a custom element
 * On mobile IMA defaults to a custom click tracking element that we have to provide and style.
 */
export const isCustomClickTrackingElementUsedAtom = selectAtom(
  videoAdsAtom,
  (state) => state.isUsingCustomClickTracking,
);

/**
 * Indicates if the native skip control should be shown.
 * It'll return false if there is no native skip control or countdown.
 * If there is a nartive countdown visivle, it'll return "countdown".
 * If there is a native skip control, it'll return "skip-control".
 */
export const selectNativeSkipControlState = selectAtom(
  videoAdsAtom,
  (state) => {
    const {
      skipTimeOffset,
      currentAdRemainingTime,
      adsDurations,
      nativeSkipControlState,
    } = state;
    const lastAdDuration = adsDurations[adsDurations.length - 1];

    if (nativeSkipControlState) {
      return "skip-control";
    }

    if (
      isNil(skipTimeOffset) ||
      skipTimeOffset === -1 ||
      isNil(currentAdRemainingTime) ||
      isNil(lastAdDuration)
    )
      return false;

    const currentTime = lastAdDuration - currentAdRemainingTime;

    // When we are over the skipTimeOffset we should show the native skip button.
    // Keep in mind that we also have a nativeSkipControlState that we checked earlier on already.
    // The check here is just a fallback.
    if (currentTime >= skipTimeOffset) return "skip-control";

    return "countdown";
  },
);

/**
 * We need to know if there's no next upcoming ad or not so at the end of the final ad we can handle things better.
 */
export function selectHasNextVideoAd(atom = videoAdsAtom): Atom<boolean> {
  const slice = selectAtom(atom, (state) => {
    const { adConfig, currentAdIndex } = state;
    return !!(
      adConfig &&
      !isNil(currentAdIndex) &&
      adConfig.tags[currentAdIndex + 1]
    );
  });
  slice.debugLabel = selectHasNextVideoAd.name;
  return slice;
}

/**
 * The ad mode determines how the ad UI is displayed.
 * For ffwd we should show 1 of x ads.
 * For replay we should show a total duration left countdown.
 * For regular we should show 1 of x ads + a duration left on the playing ad.
 */
export const selectAdMode = selectAtom(videoAdsAtom, ({ adConfig }) => {
  if (!adConfig || !adConfig.tags[0]) return null;

  if (adConfig.request_type === "live") {
    return "live";
  }

  if (isFFwdAdTag(adConfig.tags[0])) {
    return "ffwd";
  }

  if (isReplayAdTag(adConfig.tags[0])) {
    return "replay";
  }

  if (adConfig.request_type === "recording") {
    return "recording";
  }
  // This means pre-roll ads with no special config, showing only the counter and skip button.
  return "regular";
});

export const selectVideoAdsPod = selectAtom(videoAdsAtom, (state) => state.pod);
