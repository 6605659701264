import { atomFamily } from "jotai/utils";
import { atomWithSuspenseQuery } from "jotai-tanstack-query";

import { queryKeys } from "@sunrise/backend-types";
import type { EPGEntryId, RecordingId } from "@sunrise/backend-types-core";
import { selectJwtUserToken } from "@sunrise/jwt";

import type { RecordingStatus } from "./GeneratedApi";
import { ngRecordingsApiAtom } from "./ng-recordings-api.atom";
import { recordingsStaleTimeAtom } from "./recordings-stale-time.atom";

export const recordingStatusByEpgEntryIdQueryAtom = atomFamily(
  (epgEntryId: EPGEntryId) => {
    const inner = atomWithSuspenseQuery((get) => {
      const api = get(ngRecordingsApiAtom);

      return {
        // NOTE: We still depend on the access token since we want the data to reload when the user's token changes.
        queryKey: queryKeys.recordingStatusByEpgEntryId(
          get(selectJwtUserToken),
          epgEntryId,
        ),
        queryFn: async () => {
          const { data, status } =
            await api.recording.getEpgEntryRecordingStatusRecordingV1EpgEntryRecordingStatusEpgEntryIdGet(
              epgEntryId,
              {
                validateStatus: () => {
                  return true;
                },
              },
            );

          // When there's no recording_id it means we got a different response due to the 404 status code and so in effect the data is not found.
          if (!data || !data.recording_id || status < 200 || status >= 300) {
            return null;
          }

          return {
            recordingId: data.recording_id as RecordingId,
            status: data.status as RecordingStatus,
            type: "single",
          };
        },
        staleTime: get(recordingsStaleTimeAtom),
        suspense: true,
      };
    });

    inner.debugLabel = `recordingStatusByEpgEntryIdQueryAtom(${epgEntryId})`;

    return inner;
  },
);
