import { useCallback } from "react";
import { useAtom } from "jotai";
import { withAtomEffect } from "jotai-effect";

import { setAdVolume } from "@sunrise/ads";
import { playerVolumeAtom } from "@sunrise/player";

const playerVolumeAtomWithEffect = withAtomEffect(playerVolumeAtom, (get) => {
  const { level } = get(playerVolumeAtom);
  setAdVolume(level * 0.01);
});

type PlayerVolumeReturn = {
  toggleMute: () => void;
  isMuted: boolean;
  setVolume: (volume: number) => void;
  increaseVolume: () => number;
  decreaseVolume: () => number;
  currentVolume: number;
};

const VOLUME_STEP = 10;

/**
 * This returns the player's volume and mute state to show in the player's volume control.
 */
export function usePlayerVolume(): PlayerVolumeReturn {
  const [volume, setVolume] = useAtom(playerVolumeAtomWithEffect);

  return {
    toggleMute: useCallback(() => {
      setVolume({ type: "toggle-mute" });
    }, [setVolume]),
    isMuted: volume.muted,
    /**
     * A value from 0 to 100.
     * When 0, the player is muted.
     * When higher than 0 the player will automatically unmute.
     */
    setVolume: useCallback(
      (level: number) => {
        setVolume({ type: "set-level", level });
      },
      [setVolume],
    ),
    increaseVolume: useCallback(() => {
      const level = Math.min(100, volume.level + VOLUME_STEP);
      setVolume({ type: "set-level", level });
      return level;
    }, [setVolume, volume.level]),
    decreaseVolume: useCallback(() => {
      const level = Math.max(volume.level - VOLUME_STEP, 0);
      setVolume({ type: "set-level", level });
      return level;
    }, [setVolume, volume.level]),
    currentVolume: volume.level,
  };
}
