import type { Getter } from "jotai";

import {
  jwtAtom,
  selectDecodedPayload,
  selectPlanUpgradeAvailable,
} from "@sunrise/jwt";
import { userAtom } from "@sunrise/yallo-user";

import {
  DialogButtonType,
  type ErrorConfiguration,
  type ErrorConfigurationLoader,
} from "./types";

/**
 * Common errors shared between StreamErrorCode and UpsellErrorCode
 * technical error names are according to https://entwicklungspark.atlassian.net/wiki/spaces/WT/pages/3108339719/Error+Messages#Specific%2FExpected%2FKnown%2Fhandled-Errors
 */

const REPLAY_NOT_ENABLED_BUT_REPLAY_AVAILABLE: ErrorConfiguration = {
  title: { key: "title_activate_replay" },
  description: [{ key: "player_error_replay_not_enabled" }],
  rejectionButton: DialogButtonType.CLOSE,
  confirmationButton: DialogButtonType.REPLAY_SETTINGS,
  technicalErrorName: "player_error_replay_not_enabled",
};

const REPLAY_NOT_ENABLED_LONG_ENOUGH: ErrorConfiguration = {
  title: { key: "player_error_replay_not_activated_long_enough_title" },
  description: [{ key: "player_error_replay_not_activated_long_enough" }],
  confirmationButton: DialogButtonType.OK,
  technicalErrorName: "player_error_replay_not_activated_long_enough",
};

const OUT_OF_REPLAY_WINDOW: ErrorConfiguration = {
  title: { key: "error_replay_out_of_bounds_title" },
  description: [{ key: "error_replay_out_of_bounds_description" }],
  confirmationButton: DialogButtonType.OK,
  technicalErrorName: "error_program_not_in_replay_window",
};

const GENERAL_ERROR: ErrorConfiguration = {
  title: { key: "general_error_title" },
  description: [{ key: "general_error_message" }],
  confirmationButton: DialogButtonType.OK,
  technicalErrorName: "general_error",
};

const LOCKED_CONTENT: ErrorConfiguration = {
  title: { key: "player_error_title" },
  description: [{ key: "player_error_locked_content" }],
  confirmationButton: DialogButtonType.OK,
  technicalErrorName: "player_error_missing_mysports_subscription",
};

const E_USER_RECORDING_LIMIT_REACHED: ErrorConfiguration = {
  title: { key: "record_menu_title_text_capacity_full" },
  description: [{ key: "record_menu_description_text_capacity_full" }],
  confirmationButton: DialogButtonType.MANAGE_RECORDINGS,
  rejectionButton: DialogButtonType.CANCEL,
  technicalErrorName: "user_recording_limit_reached",
};

const sessionExpired = (technicalErrorName?: string): ErrorConfiguration => ({
  confirmationButton: DialogButtonType.OK,
  title: { key: "error_login_expired_title" },
  description: [{ key: "error_login_expired_description" }],
  technicalErrorName: technicalErrorName ?? "session_expired",
});

const createErrorConfigForConcurrentStreams = async (
  getter: Getter,
): Promise<ErrorConfiguration> => {
  const user = await getter(userAtom);

  const plan = user.data?.account?.plan?.display_name;
  const sessionLimit = selectDecodedPayload(getter(jwtAtom))?.feature_set
    .features.session_limit;
  const planUpgradeAvailable = selectPlanUpgradeAvailable(getter(jwtAtom));
  const dynamicParams = [plan ?? "", sessionLimit ?? 1];

  return {
    title: { key: "player_error_too_many_concurrent_streams_general" },
    confirmationButton: DialogButtonType.OK,
    description: planUpgradeAvailable
      ? [
          {
            key: "player_error_too_many_concurrent_streams_can_upgrade",
            params: dynamicParams,
          },
        ]
      : [
          {
            key: "player_error_too_many_concurrent_streams_no_upgrade_possible",
            params: dynamicParams,
          },
        ],
    technicalErrorName: planUpgradeAvailable
      ? "player_error_concurrent_stream_upgrade_available"
      : "player_error_concurrent_stream_no_upgrade_available",
  };
};

const GEO_BLOCKED: ErrorConfiguration = {
  title: { key: "player_error_title" },
  description: [{ key: "player_error_geo_lock" }],
  confirmationButton: DialogButtonType.OK,
  technicalErrorName: "player_error_geo_lock",
};

const UPSELL_SERIES_RECORDING: ErrorConfiguration = {
  title: { key: "title_upsell_record_series" },
  description: [{ key: "description_upsell_record_series" }],
  confirmationButton: DialogButtonType.OK,
  technicalErrorName: "upsell_record_series",
  upsell: "recordings",
};

/**
 * This maps the possible error codes to the corresponding error configuration.
 */
export const ERROR_MAPPING: Record<string, ErrorConfigurationLoader> &
  Record<"GENERAL_ERROR", ErrorConfiguration> = {
  //
  // General (unknown) Error
  //
  GENERAL_ERROR: GENERAL_ERROR,
  UPSELL_GENERAL_ERROR: GENERAL_ERROR,
  E_USER_RECORDING_LIMIT_REACHED: E_USER_RECORDING_LIMIT_REACHED,

  "CHANNELPACKAGECHECK.CHANNEL_NOT_IN_ANY_PACKAGES": LOCKED_CONTENT,
  UPSELL_CHANNEL_LOCKED: LOCKED_CONTENT,

  E_IS_ABROAD: GEO_BLOCKED,
  "GEOCHECK.USER_ABROAD": GEO_BLOCKED,

  E_NOT_ENOUGH_ADS_WATCHED: {
    title: { key: "adblocker_modal_title" },
    description: [{ key: "adblocker_modal_content" }],
    confirmationButton: DialogButtonType.OK,
    technicalErrorName: "adblocker",
    upsell: "upgrade",
  },

  /**
   * When the user has no replay permissions if they attempt to pause a live stream.
   */
  UPSELL_NO_PAUSE: {
    title: { key: "upsell_pause_title" },
    description: [{ key: "upsell_pause_description" }],
    confirmationButton: DialogButtonType.OK,
    technicalErrorName: "upsell_pause",
    upsell: "replay",
  },

  UPSELL_SINGLE_RECORDING_FUTURE: {
    title: { key: "title_upsell_record_future_program" },
    description: [{ key: "description_upsell_record_future_program" }],
    confirmationButton: DialogButtonType.OK,
    technicalErrorName: "upsell_record_single_future",
    upsell: "recordings",
  },

  UPSELL_SINGLE_RECORDING: {
    title: { key: "title_upsell_record_past_or_live_program" },
    description: [{ key: "description_upsell_record_past_or_live_program" }],
    confirmationButton: DialogButtonType.OK,
    technicalErrorName: "upsell_record_single",
    upsell: "recordings",
  },

  UPSELL_SERIES_RECORDING: UPSELL_SERIES_RECORDING,
  E_USER_CANNOT_RECORD_ASSETS: UPSELL_SERIES_RECORDING,

  /**
   * When the user has no replay permissions at all.
   */
  UPSELL_NO_REPLAY: {
    title: { key: "upsell_replay_title" },
    description: [{ key: "upsell_replay_description" }],
    confirmationButton: DialogButtonType.CANCEL,
    // TODO: we should differentiate between trying to replay past, live or rewinding.
    technicalErrorName: "upsell_replay_on_past",
    upsell: "replay",
  },

  /**
   * When the user has replay permissions but the stream is outside of the replay window.
   *
   * Technically not really an upsell error. Because there is nothing the user can buy to make it work.
   */
  UPSELL_OUTSIDE_REPLAY_WINDOW: OUT_OF_REPLAY_WINDOW,
  "REPLAYACTIVATIONCHECK.OUT_OF_BOUNDS": OUT_OF_REPLAY_WINDOW,
  E_START_NOT_IN_TIME_WINDOW: OUT_OF_REPLAY_WINDOW,

  /**
   * When the user has replay permissions
   * and the stream is inside the replay window
   * BUT the channel does not have replay enabled
   */
  E_REPLAY_NOT_ACTIVATED: REPLAY_NOT_ENABLED_BUT_REPLAY_AVAILABLE,
  "REPLAYACTIVATIONCHECK.REPLAY_NOT_ACTIVATED":
    REPLAY_NOT_ENABLED_BUT_REPLAY_AVAILABLE,
  UPSELL_REPLAY_NOT_ENABLED: REPLAY_NOT_ENABLED_BUT_REPLAY_AVAILABLE,

  /**
   * When the user has replay permissions
   * and the stream is inside the replay window
   * and the channel has replay enabled
   * BUT the channel did not have replay enabled at the time this content aired.
   */
  "REPLAYACTIVATIONCHECK.NOT_ACTIVATED_LONG_ENOUGH":
    REPLAY_NOT_ENABLED_LONG_ENOUGH,
  // NOTE: The recordings service returns an error code like this.
  USER_REPLAY_NOT_ACTIVATED_LONG_ENOUGH: REPLAY_NOT_ENABLED_LONG_ENOUGH,
  UPSELL_REPLAY_NOT_ENABLED_LONG_ENOUGH: REPLAY_NOT_ENABLED_LONG_ENOUGH,
  E_REPLAY_NOT_ACTIVATED_LONG_ENOUGH: REPLAY_NOT_ENABLED_LONG_ENOUGH,

  // IDEA: Inject the number of hours the user can stream in total in the title.
  UPSELL_EXCEEDED_STREAM_DURATION_LIMIT: {
    title: { key: "title_upsell_streaming_limit_reached" },
    description: [{ key: "description_upsell_streaming_limit_reached" }],
    confirmationButton: DialogButtonType.OK,
    technicalErrorName: "player_error_too_many_concurrent_streams",
    upsell: "upgrade",
  },

  /**
   * When the concurrent streams limit is exceeded.
   */
  "MULTISTREAMCHECK.TOO_MANY_STREAMS": createErrorConfigForConcurrentStreams,

  /**
   * TECHNICAL ERRORS
   */
  error_user_logged_out: {
    confirmationButton: DialogButtonType.OK,
    title: { key: "subscription_inactive_error_title" },
    description: [{ key: "subscription_inactive_error_error" }],
    technicalErrorName: "error_user_logged_out",
  },

  session_expired: sessionExpired(),

  // When we are offline
  offline: {
    type: "blocking",
    title: { key: "bad_connection_overlay_title" },
    description: [{ key: "bad_connection_overlay_subtitle" }],
    technicalErrorName: "no_internet_connection",
  },

  error_user_not_known: sessionExpired("error_user_not_known"),
};
