import { atom } from "jotai";

import { ffwdCurrentAdBreakAtom } from "./ffwd-current-ad-break.atom";
import { areVideoAdsPlayingAtom } from "./video-ads.atom";

/**
 * Should the skip FFWD ad button be visible or not.
 */
export const skipFfwdAdButtonVisibleAtom = atom((get) => {
  const ffwdCurrentAdBreak = get(ffwdCurrentAdBreakAtom);
  const videoAdsPlaying = get(areVideoAdsPlayingAtom);

  return !!ffwdCurrentAdBreak && !videoAdsPlaying;
});
