import { atomWithMutation } from "jotai-tanstack-query";
import { isNil } from "lodash";

import { hostsAtom, httpClientAtom } from "@sunrise/http-client";

import { deleteAccount } from "./auth.service";

export const deleteAccountMutationAtom = atomWithMutation((get) => {
  return {
    mutationKey: ["delete-account"],
    mutationFn: async () => {
      const host = get(hostsAtom).api;
      const httpClient = get(httpClientAtom);

      if (isNil(host)) throw new Error("Host is not set");
      if (isNil(httpClient.privateApi))
        throw new Error("PrivateApi not initialized");

      return deleteAccount(host, httpClient.privateApi);
    },
  };
});
