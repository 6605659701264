/**
 * Allow HTTP codes in the 200 range (default), 400 and 404 to be marked as successful in Axios
 * By default everything not in the 200 range throws an error and triggers our ErrorBoundary
 * Some calls (detail pages) can be 400 (invalid id) or 404 (id not found) and should not trigger
 * an ErrorBoundary but can be handled by showing a NotFound component.
 *
 * @param code The HTTP Status code
 * @returns true if the code is in the 200 range, 400 or 404
 */
export const validate400and404status = (code: number) =>
  (code >= 200 && code < 300) || code === 400 || code === 404;
