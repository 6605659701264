import { useCallback, useEffect, useMemo, useRef } from "react";
import {
  addMilliseconds,
  differenceInMilliseconds,
  subMilliseconds,
} from "date-fns";
import { atom, useAtomValue, useSetAtom } from "jotai";
import { useAtomCallback } from "jotai/utils";

import { ffwdMarkersAtom, ffwdMarkersVisibleAtom } from "@sunrise/ads";
import {
  actionPlayerConfirmLinearSeekTime,
  actionPlayerSetSeekTime,
  playerAtom,
  playerDateTimeConverterAtom,
} from "@sunrise/player";
import { getLiveProgress } from "@sunrise/time";
import {
  isDefined,
  isNil,
  type Nullable,
  stableLoadable,
} from "@sunrise/utils";
import { getPlayerManager } from "@sunrise/yallo-common-player-manager";

import { epgSeekbarProgressAtom } from "../epg-seekbar-progress.atom";
import { SEEKBAR_STEP_TIME_IN_MS } from "../player-controls.constants";
import type {
  PlayerRequestSeekbarReturn,
  SeekbarBreak,
  SeekbarProgressResult,
} from "../types";
import { composeSeekbarHook } from "../utils/compose-seekbar-hook";

/**
 * This hook is used to handle the linear seekbar for EPG (linear) data.
 * It provides functions for seeking forward, backward, and confirming the seek.
 * It does use a loadable to make the hook itself not suspend anymore.
 *
 * @param seekStepTimeInMs - The time in milliseconds to seek forward or backward.
 * @param isEnabled - Indicates whether the seekbar is enabled or not.
 * @returns Nullable<PlayerRequestSeekbarReturn> - The seekbar information for EPG (linear) data.
 *   It uses the same output as useOnDemandSeekbar.
 *
 * @example
 * ```typescript
 * const seekbar = useLinearSeekbar({ seekStepTimeInMs: 1000, isEnabled: true });
 * ```
 */
export function useLinearSeekbar(
  {
    seekStepTimeInMs,
    isEnabled,
  }: { seekStepTimeInMs: number; isEnabled: boolean } = {
    seekStepTimeInMs: SEEKBAR_STEP_TIME_IN_MS,
    isEnabled: true,
  },
): Nullable<PlayerRequestSeekbarReturn> {
  const seekbarProgress = useLinearSeekbarData({ isEnabled });
  const dispatchPlayer = useSetAtom(playerAtom);

  const actualCurrentTime = seekbarProgress?.currentTime;

  // We have a ref to the currentTime since we don't want to rebuild the actions all the time.
  const currentTimeRef = useRef(actualCurrentTime);
  useEffect(() => {
    currentTimeRef.current = actualCurrentTime;
  }, [actualCurrentTime]);
  const converter = useAtomValue(playerDateTimeConverterAtom);

  const forward = useCallback(
    async (ms?: number) => {
      const newTime = currentTimeRef.current;
      if (isNil(newTime) || !converter) return;

      const time = await getPlayerManager().couldSeekToInCurrentPlayRequest(
        converter.fromDate(addMilliseconds(newTime, ms ?? seekStepTimeInMs)),
      );

      if (!time) {
        return;
      }

      dispatchPlayer(actionPlayerSetSeekTime(time));
    },
    [seekStepTimeInMs, dispatchPlayer, converter],
  );

  const backward = useCallback(
    async (ms?: number) => {
      const newTime = currentTimeRef.current;
      if (isNil(newTime) || !converter) return;

      const time = await getPlayerManager().couldSeekToInCurrentPlayRequest(
        converter.fromDate(subMilliseconds(newTime, ms ?? seekStepTimeInMs)),
      );

      if (!time) {
        return;
      }

      dispatchPlayer(actionPlayerSetSeekTime(time));
    },
    [seekStepTimeInMs, dispatchPlayer, converter],
  );

  const toPercentage = useCallback(
    async (percentage: number, immediate?: boolean) => {
      if (!converter || !seekbarProgress?.startTime || !seekbarProgress.endTime)
        return;

      const totalDifference = differenceInMilliseconds(
        seekbarProgress?.endTime,
        seekbarProgress?.startTime,
      );
      const percentageMilliseconds = (totalDifference * percentage) / 100;

      const resultTime = addMilliseconds(
        seekbarProgress?.startTime,
        percentageMilliseconds,
      );

      const time = await getPlayerManager().couldSeekToInCurrentPlayRequest(
        converter.fromDate(resultTime),
        !immediate,
      );

      if (!time) {
        return;
      }

      if (immediate) {
        await getPlayerManager().seekToInCurrentPlayRequest(time);
        return;
      }

      dispatchPlayer(actionPlayerSetSeekTime(time));
    },
    [dispatchPlayer, converter, seekbarProgress],
  );

  const confirm = useAtomCallback(
    useCallback(
      async (get) => {
        const isSeeking = !!get(playerAtom).seekTime;
        if (!isSeeking || !currentTimeRef.current || !converter) return;

        dispatchPlayer(
          actionPlayerConfirmLinearSeekTime(currentTimeRef.current),
        );

        await getPlayerManager().seekToInCurrentPlayRequest(
          converter.fromDate(currentTimeRef.current),
        );
      },
      [converter, dispatchPlayer],
    ),
  );

  const markers = useAtomValue(ffwdMarkersAtom);
  const markersVisible = useAtomValue(ffwdMarkersVisibleAtom);
  const breaks: SeekbarBreak[] = useMemo(() => {
    if (!seekbarProgress || !markers || !markersVisible) {
      return [];
    }

    return markers
      .map((marker) => {
        const startsAtPercentage = getLiveProgress(
          seekbarProgress.startTime,
          seekbarProgress.endTime,
          marker.start,
        );
        const endsAtPercentage = getLiveProgress(
          seekbarProgress.startTime,
          seekbarProgress.endTime,
          marker.end,
        );

        if (!startsAtPercentage || !endsAtPercentage) {
          return null;
        }

        return {
          startsAtPercentage,
          lengthInPercentage: endsAtPercentage - startsAtPercentage,
          kind: "normal" as const,
        };
      })
      .filter(isDefined);
  }, [seekbarProgress, markers, markersVisible]);

  if (!isEnabled) return null;

  const composed = composeSeekbarHook(seekbarProgress);

  return {
    ...composed,
    breaks,
    seek: {
      forward,
      backward,
      confirm,
      toPercentage,
    },
  };
}

const LINEAR_SEEKBAR_EMPTY_ATOM = atom<null>(null);
const STABLE_EPG_SEEKBAR_ATOM = stableLoadable(epgSeekbarProgressAtom);

function useLinearSeekbarData({
  isEnabled,
}: {
  isEnabled: boolean;
}): Nullable<SeekbarProgressResult> {
  return useAtomValue(
    isEnabled ? STABLE_EPG_SEEKBAR_ATOM : LINEAR_SEEKBAR_EMPTY_ATOM,
  );
}
