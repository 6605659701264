import { atomWithMutation, queryClientAtom } from "jotai-tanstack-query";
import { isNil } from "lodash";

import { endpoints, queryKeys } from "@sunrise/backend-types";
import { hostsAtom, httpClientAtom } from "@sunrise/http-client";
import { selectJwtUserToken } from "@sunrise/jwt";
import { userAtom } from "@sunrise/yallo-user";

export const toggleReplayAtom = atomWithMutation((get) => {
  return {
    mutationKey: ["toggle-replay"],
    mutationFn: async () => {
      const currentUserState = await get(userAtom);
      if (!currentUserState?.data?.global_client_settings.replay_activated) {
        const hosts = get(hostsAtom);
        const host = hosts.api;
        if (isNil(host)) throw new Error("api host is not defined");
        const privateApi = get(httpClientAtom).privateApi;
        if (isNil(privateApi)) throw new Error("privateApi is not defined");
        await privateApi.post(endpoints.replayActivateAll(host));
      } else {
        const hosts = get(hostsAtom);
        const host = hosts.api;
        if (isNil(host)) throw new Error("api host is not defined");
        const privateApi = get(httpClientAtom).privateApi;
        if (isNil(privateApi)) throw new Error("privateApi is not defined");
        await privateApi.delete(endpoints.replayDeactivateAll(host));
      }
    },
    onSuccess: () => {
      const queryClient = get(queryClientAtom);
      queryClient.invalidateQueries({
        queryKey: queryKeys.user(get(selectJwtUserToken)),
      });
    },
  };
});
