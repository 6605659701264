import { featureAtom } from "@sunrise/feature-flags";

/**
 * by default true, must be updated by `get-config` of the specific TV platforms
 */
export const reloadOnCodecSwitchAtom = featureAtom(
  "reload-on-codec-switch",
  false,
  {
    description:
      "Whether the player should reload the stream when the audio codec is changed. Can be overriden for testing.",
  },
);
