import { atom } from "jotai";

import type { Nullable } from "@sunrise/utils";

/**
 * For some cases we want to actually use mocked data.
 * Mocked data will allow us to test edge cases.
 * Some mock data can be generated. Some data can be hardcoded.
 *
 * This boolean determines if all features should use their mocked data or not.
 */
export const mockDataAtom = atom(false);

/**
 * Indicate if the client is Tizen or web to request the respective
 * configuration from the backend
 */
export const platformAtom = atom<Nullable<string>>(null);
export const settingsVersionAtom = atom<Nullable<string>>(null);
export const settingsV2VersionAtom = atom<Nullable<string>>(null);
