import { featureAtom } from "@sunrise/feature-flags";

export const alwaysShowPlayoutButtonsFeatureAtom = featureAtom(
  "always-show-playout-buttons",
  false,
  {
    description:
      "When set to true, we will show the buttons even though the client knows playout is not possible and no upsell is available. This is just used for local testing purposes. Should never be set to true in production.",
  },
);
