import { AxiosError } from "axios";
import { subMinutes } from "date-fns";

import { PlayerContentType } from "@sunrise/backend-ng-events";
import { ngStreamApiAtom, type StreamSchema } from "@sunrise/backend-ng-stream";
import type {
  RecordingStream,
  ReplayStream,
  SimpleStream,
  StreamErrorCode,
} from "@sunrise/backend-types";
import { selectStreamTypeAsString } from "@sunrise/jwt";
import type { StreamResolveFunction } from "@sunrise/player-manager";
import type { Store } from "@sunrise/store";
import { nowSecondAtom } from "@sunrise/time";
import { epgEntryByIdAtom } from "@sunrise/yallo-epg";
import type { PlayRequest } from "@sunrise/yallo-player-types";
import { recordingByIdNgAtom } from "@sunrise/yallo-recordings";
import { getReplayStartTime } from "@sunrise/yallo-replay";
import {
  StreamBlockedByBackendError,
  StreamFailedToLoadError,
  StreamNotFoundError,
} from "@sunrise/yallo-stream";

import { acquiringStreamsFailsFeatureAtom } from "./acquiring-streams-fails.feature.atom";
import { stringToStreamType } from "./helpers/string-to-stream-type";

const GET_RECORDING_ID_FROM_STREAM_REGEX = /(\d{2,10})/;

/**
 * We use the stream endpoints in order to play out content on the ng backend.
 */
export async function playRequestToStream(
  store: Store,
  request: PlayRequest,
): Promise<StreamResolveFunction> {
  const failure = store.get(acquiringStreamsFailsFeatureAtom);

  if (failure === "all-on-acquire") {
    return () => {
      throw new StreamFailedToLoadError("forced failure: all");
    };
  }

  const createSimpleStream = (
    data: StreamSchema,
    shouldFail = false,
  ): SimpleStream => {
    if (shouldFail) {
      const url = new URL(data.stream_url);
      url.hostname = "will-fail.epicly";

      return {
        url: url.toString(),
        licenseUrl: data.license_url,
        /**
         * NOTE: We get it from the JWT. But theoretically it can differ per stream.
         */
        type: stringToStreamType(store.get(selectStreamTypeAsString)),
        /**
         * WARN: This is hardcoded to zattoo ... when we ever get different stream providers the backend will need to provide this information.
         */
        provider: "zattoo",
      };
    }

    return {
      url: data.stream_url,
      licenseUrl: data.license_url,
      /**
       * NOTE: We get it from the JWT. But theoretically it can differ per stream.
       */
      type: stringToStreamType(store.get(selectStreamTypeAsString)),
      /**
       * WARN: This is hardcoded to zattoo ... when we ever get different stream providers the backend will need to provide this information.
       */
      provider: "zattoo",
    };
  };

  const handleError = (err: unknown): Error => {
    const axiosError =
      err instanceof AxiosError &&
      err.response?.status === 412 &&
      (err as AxiosError<{
        readonly detail: { readonly message: StreamErrorCode };
      }>);

    if (axiosError && axiosError.response?.data?.detail?.message) {
      throw new StreamBlockedByBackendError(
        axiosError.response.data.detail.message,
      );
    }

    // Fallback
    throw new StreamFailedToLoadError(
      err instanceof Error ? err.message : "unknown-error-requesting-stream",
      {
        cause: err,
      },
    );
  };

  if (
    failure === "live-on-acquire" &&
    request.type === PlayerContentType.Live
  ) {
    return () => {
      throw new StreamFailedToLoadError("forced failure: live");
    };
  }

  if (
    failure === "replay-on-acquire" &&
    request.type === PlayerContentType.Replay
  ) {
    return () => {
      throw new StreamFailedToLoadError("forced failure: replay");
    };
  }

  if (
    failure === "recordings-on-acquire" &&
    request.type === PlayerContentType.Recording
  ) {
    return () => {
      throw new StreamFailedToLoadError("forced failure: recordings");
    };
  }

  switch (request.type) {
    case PlayerContentType.Replay:
    case PlayerContentType.Live: {
      const api = store.get(ngStreamApiAtom);

      return async () => {
        const isReplay = request.type === PlayerContentType.Replay;
        // For replay we need to know the startTime of the epg entry.
        const epg = isReplay
          ? await store.get(epgEntryByIdAtom(request.epgId))
          : null;

        const now = store.get(nowSecondAtom);
        const start = epg?.data ? getReplayStartTime(epg.data, now) : null;

        try {
          const result =
            await api.stream.getChannelStreamStreamV1ChannelsChannelIdStreamGet(
              request.channelId,
              start ? { start: start.toISOString() } : {},
            );

          const shouldFail =
            (failure === "live-on-stream" &&
              request.type === PlayerContentType.Live) ||
            (failure === "replay-on-stream" &&
              request.type === PlayerContentType.Replay) ||
            failure === "all-on-stream";

          const base = createSimpleStream(result.data, shouldFail);

          if (start) {
            return {
              ...base,
              offset: start.getTime(),
              linearStartTime: start,
              markers: result.data.gt12_schedule ?? undefined,
            } satisfies ReplayStream;
          }

          return base;
        } catch (err: unknown) {
          throw handleError(err);
        }
      };
    }
    case PlayerContentType.Recording: {
      return async () => {
        const api = store.get(ngStreamApiAtom);

        // Fetch recording details first. So we can get the correct providerRecordingId.
        // NOTE: Later on we could embed the providerRecordingId in the playRequest. So we would not have to do an additional request here.
        const recordingQuery = await store.get(
          recordingByIdNgAtom(request.recordingId),
        );

        if (
          !recordingQuery.data ||
          recordingQuery.data.type !== "recording" ||
          !recordingQuery.data.stream_service_url
        ) {
          throw new StreamNotFoundError({
            type: "recording_missing_stream_url",
            recordingId: request.recordingId,
          });
        }

        const providerRecordingId = GET_RECORDING_ID_FROM_STREAM_REGEX.exec(
          recordingQuery.data.stream_service_url,
        )?.[0];

        if (!providerRecordingId) {
          throw new StreamNotFoundError({
            type: "recording_missing_provider_recording_id",
            recordingId: request.recordingId,
          });
        }

        try {
          const result =
            await api.stream.getRecordingStreamStreamV1RecordingsProviderRecordingIdStreamGet(
              providerRecordingId,
            );

          const base = createSimpleStream(
            result.data,
            failure === "recordings-on-stream" || failure === "all-on-stream",
          );

          return {
            ...base,
            /* The point in time that the recording started in linear broadcast */
            linearStartTime: subMinutes(
              new Date(recordingQuery.data.epg_start),
              recordingQuery.data.padding_start_minutes,
            ),
            markers: result.data.gt12_schedule ?? undefined,
          } satisfies RecordingStream;
        } catch (err) {
          throw handleError(err);
        }
      };
    }
    default: {
      throw new Error("unknown stream type");
    }
  }
}
