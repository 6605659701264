import { atomEffect } from "jotai-effect";

import { isLegacyBackendAtom } from "@sunrise/backend-core";
import { hostsAtom } from "@sunrise/http-client";
import {
  actionJWTClear,
  actionJWTSetTokens,
  jwtAtom,
  selectCanLogout,
} from "@sunrise/jwt";

import { authenticateWithToken } from "../auth.service";
import { deviceTypeAtom } from "../device-type.atom";
import { idTokenAtom, PROCESSED } from "../id-token.atom";

export const loginIdTokenEffect = atomEffect((get, set) => {
  const idToken = get(idTokenAtom);
  if (!idToken || idToken === PROCESSED) return;

  const hosts = get(hostsAtom);
  const deviceType = get(deviceTypeAtom);
  const isLegacyBackend = get(isLegacyBackendAtom);

  let isCurrentRequest = true;
  authenticateWithToken(hosts.api, deviceType, idToken, !isLegacyBackend)
    .then((response) => {
      if (!isCurrentRequest) return;

      if (!response.client_jwt_token || !response.client_jwt_refresh_token) {
        set(idTokenAtom, PROCESSED);

        // We were not able to authenticate, so we need to clear the JWT store.
        // Unless we are currently an anonymous user. Anonymous users can not log out.
        const canLogout = get(selectCanLogout);
        if (canLogout) {
          set(jwtAtom, actionJWTClear());
        }
        return;
      }

      // Accept the new tokens so we log in as the newly authenticated user.
      set(
        jwtAtom,
        actionJWTSetTokens({
          accessToken: response.client_jwt_token,
          refreshToken: response.client_jwt_refresh_token,
          wsToken: response.client_jwt_websocket_token ?? null,
        }),
      );

      set(idTokenAtom, PROCESSED);
    })
    .catch(() => {
      if (!isCurrentRequest) return;

      set(idTokenAtom, PROCESSED);
      // We do not know for sure what happened. So we do not want to clear any JWT tokens either.
    });

  return () => {
    isCurrentRequest = false;
  };
});
