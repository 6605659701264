import { featureAtom } from "@sunrise/feature-flags";

export const acquiringStreamsFailsFeatureAtom = featureAtom(
  "acquiring-streams-fails",
  false,
  {
    description:
      "When set to a value other than false, streams will fail. on acquire means that it will fail when we are trying to get the stream from the backend. On stream means that it will fail when we are trying to play the stream inside the player.",
    values: [
      "all-on-acquire",
      "live-on-acquire",
      "replay-on-acquire",
      "recordings-on-acquire",
      "live-on-stream",
      "replay-on-stream",
      "recordings-on-stream",
      false,
    ],
  },
);
