import { atom } from "jotai";
import { atomFamily } from "jotai/utils";

import type { EPGEntryId, RecordingId } from "@sunrise/backend-types-core";
import { selectCanRecord, selectCanReplay } from "@sunrise/jwt";

import { parseContinueWatchingStatus } from "../helpers/parse-continue-watching-status";
import {
  continueWatchingByEpgEntryAtom,
  continueWatchingByRecordingAtom,
} from "../socket/continue-watching-socket-updates.atom";
import type { ContinueWatchingStatus } from "../types";
import { continueWatchingEpgByEpgIdorRecordingIdQueryAtom } from "./continue-watching-status-by-epg-or-recording-id.query.atom";

export const continueWatchingStatusByEpgIdOrRecordingIdAtom = atomFamily(
  (props: { epgId: EPGEntryId } | { recordingId: RecordingId }) => {
    const wsData = atom((get) =>
      "epgId" in props
        ? get(continueWatchingByEpgEntryAtom)[props.epgId]
        : get(continueWatchingByRecordingAtom)[props.recordingId],
    );

    return atom<Promise<ContinueWatchingStatus | null>>(async (get) => {
      if (!get(selectCanRecord) && !get(selectCanReplay)) return null;

      // Fetch from the socket first.
      // If not found, fetch from the rest endpoint.
      const item =
        (await get(wsData)?.["payload"]) ??
        (await get(continueWatchingEpgByEpgIdorRecordingIdQueryAtom(props)))
          .data;

      if (!item) {
        return null;
      }

      return parseContinueWatchingStatus(item);
    });
  },
);
