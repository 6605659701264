import type { Opaque } from "ts-essentials";

import type { Nullable } from "@sunrise/utils";

export const DEFAULT_AUDIO_CHANNELS = 2;

export type PlayerState =
  | "playing"
  | "loading"
  | "loaded"
  | "paused"
  | "error"
  | "idle"
  | "stopped"
  | "suspended";

export type AutoplayError = Error & { name: "NotAllowedError" };

export type PlayerLiveBufferSettings = {
  min: number;
  max: number;
  delay: number;
};

export type PlayerDelayedBufferSettings = {
  min: number;
  max: number;
};

export type GetPlayerBufferSettings = {
  live: () => PlayerLiveBufferSettings | null;
  delayed: () => PlayerDelayedBufferSettings | null;
};

export type ShakaLoadError = Error & {
  category: number;
  code: number;
  data: unknown[];
  severity: number;
};

/**
 * An internal identifier to keep track of which track we mean.
 * For us, tracks are mapped a bit differently than in the player.
 * In the player there's an id for every bit rate + language combination.
 *
 * For us, an id can mean something else like just the combination of language + channels count.
 *
 * REQUIRED: the language must be the first part of the id. And the parts need to be split with a dash.
 */
export type TrackId = Opaque<string, "track-id">;

export type PlayerTrack = {
  id: TrackId;
  label: string;
  lang: Nullable<string>;
};

export type AudioFormat = "Stereo" | "Dolby";

export type PlayerAudioTrack = PlayerTrack & {
  channelsCount: number;
  format: AudioFormat;
};

export type PlayerSubtitleTrack = PlayerTrack & {
  //
};

export type FairPlayConfiguration = {
  fairplayServerCertificate?: Uint8Array;
  fairplayInitDataTransform?: shaka.extern.InitDataTransform;
};

export type PlayerPreferredTrack = {
  audioLanguage: Nullable<string>;
  subtitleLanguage: Nullable<string>;
  audioFormat: AudioFormat;
};

export type FullscreenMode = "native" | "web" | false;

export type WebkitHTMLVideoElement = HTMLVideoElement & {
  webkitEnterFullscreen(): void;
  webkitExitFullscreen(): void;
};
