import { useCallback, useEffect, useMemo, useRef } from "react";
import { addSeconds, minutesToSeconds } from "date-fns";
import { atom, useAtomValue, useSetAtom } from "jotai";
import { loadable } from "jotai/utils";

import { ffwdMarkersAtom, ffwdMarkersVisibleAtom } from "@sunrise/ads";
import {
  actionPlayerSetSeekTime,
  playerAtom,
  selectPlayerCurrentStream,
} from "@sunrise/player";
import { getLiveProgress } from "@sunrise/time";
import { isDefined, isNil, type Nullable } from "@sunrise/utils";
import { getPlayerManager } from "@sunrise/yallo-common-player-manager";

import { SEEKBAR_STEP_TIME_IN_MS } from "../player-controls.constants";
import { recordingSeekbarProgressAtom } from "../recording-seekbar-progress.atom";
import type { PlayerRequestSeekbarReturn, SeekbarBreak } from "../types";
import { formatTime } from "../utils/format-time";

const RECORDING_SEEKBAR_EMPTY_ATOM = atom(null);

/**
 * This hook is used to handle the seekbar for recordings (on demand) data.
 * It provides functions for seeking forward, backward, and confirming the seek.
 * It does use a loadable to make the hook itself not suspend anymore.
 *
 * NOTE: In the future, VOD/trailer content should work with this hook as well.
 *
 * @param seekStepTimeInMs - The time in milliseconds to seek forward or backward.
 * @param isEnabled - Indicates whether the seekbar is enabled or not.
 * @returns Nullable<PlayerRequestSeekbarReturn> - The seekbar information for EPG (linear) data.
 *   It uses the same output as useLinearSeekbar.
 *
 * @example
 * ```typescript
 * const seekbar = useRecordingSeekbar({ seekStepTimeInMs: 1000, isEnabled: true });
 * ```
 */
export function useRecordingSeekbar(
  {
    seekStepTimeInMs,
    isEnabled,
  }: { seekStepTimeInMs: number; isEnabled: boolean } = {
    seekStepTimeInMs: SEEKBAR_STEP_TIME_IN_MS,
    isEnabled: true,
  },
): Nullable<PlayerRequestSeekbarReturn> {
  const seekbarProgressLoadable = useAtomValue(
    loadable(
      isEnabled ? recordingSeekbarProgressAtom : RECORDING_SEEKBAR_EMPTY_ATOM,
    ),
  );
  const dispatchPlayer = useSetAtom(playerAtom);
  const currentStream = useAtomValue(selectPlayerCurrentStream);

  const seekbarProgress =
    seekbarProgressLoadable.state === "hasData"
      ? seekbarProgressLoadable.data
      : null;

  const progress = getProgress(
    seekbarProgress?.time,
    seekbarProgress?.duration,
  );
  const progressWithoutSeek = getProgress(
    seekbarProgress?.timeWithoutSeeking,
    seekbarProgress?.duration,
  );

  const time = seekbarProgress?.time;

  const { paddingStartTimeInMinutes, paddingEndTimeInMinutes } =
    seekbarProgress ?? {};

  const durationLeft =
    seekbarProgress?.duration && time
      ? formatTime(seekbarProgress.duration - time, true)
      : "0:00:00";

  const durationLeftWithoutSeek =
    seekbarProgress?.duration && seekbarProgress.timeWithoutSeeking
      ? formatTime(
          seekbarProgress.duration - seekbarProgress.timeWithoutSeeking,
          true,
        )
      : "0:00:00";

  // in seconds (for recordings at least)
  const timeRef = useRef(time);
  useEffect(() => {
    timeRef.current = time;
  }, [time]);

  const forward = useCallback(
    async (ms?: number) => {
      if (isNil(timeRef.current)) return;

      // convert seekStepTimeInMs to seconds
      const newTime = timeRef.current + (ms ?? seekStepTimeInMs) / 1000;

      const newSeekTime =
        await getPlayerManager().couldSeekToInCurrentPlayRequest(newTime);
      if (!newSeekTime) {
        return;
      }

      dispatchPlayer(actionPlayerSetSeekTime(newSeekTime));
    },
    [seekStepTimeInMs, dispatchPlayer],
  );

  const backward = useCallback(
    async (ms?: number) => {
      if (isNil(timeRef.current)) return;

      // convert seekStepTimeInMs to seconds
      const newTime = timeRef.current - (ms ?? seekStepTimeInMs) / 1000;

      const newSeekTime =
        await getPlayerManager().couldSeekToInCurrentPlayRequest(newTime);
      if (!newSeekTime) {
        return;
      }

      dispatchPlayer(actionPlayerSetSeekTime(newSeekTime));
    },
    [seekStepTimeInMs, dispatchPlayer],
  );

  const duration = seekbarProgress?.duration;
  const toPercentage = useCallback(
    async (percentage: number, immediate?: boolean) => {
      if (!duration) return;

      // convert percentage to milliseconds
      const newTime = (percentage / 100) * duration;

      // returns a new time that we could seek to
      const time =
        await getPlayerManager().couldSeekToInCurrentPlayRequest(newTime);

      if (isNil(time)) {
        return;
      }

      // immediate means we need to jump the player to that time.
      if (immediate) {
        await getPlayerManager().seekToInCurrentPlayRequest(time);
      } else {
        // Otherwise, we just set the seek time.
        dispatchPlayer(actionPlayerSetSeekTime(time));
      }
    },
    [dispatchPlayer, duration],
  );

  const confirm = useCallback(async () => {
    if (isNil(timeRef.current)) return;

    await getPlayerManager().seekToInCurrentPlayRequest(timeRef.current);
  }, []);

  const startPercentage =
    paddingStartTimeInMinutes &&
    seekbarProgress?.duration &&
    (minutesToSeconds(paddingStartTimeInMinutes) / seekbarProgress.duration) *
      100;

  const endPercentage =
    paddingEndTimeInMinutes &&
    seekbarProgress?.duration &&
    (1 - minutesToSeconds(paddingEndTimeInMinutes) / seekbarProgress.duration) *
      100;

  const currentTime = formatTime(
    (time ?? 0) - minutesToSeconds(paddingStartTimeInMinutes ?? 0),
    true,
  ); // NOTE: seconds

  const currentTimeWithoutSeek = formatTime(
    (seekbarProgress?.timeWithoutSeeking ?? 0) -
      minutesToSeconds(paddingStartTimeInMinutes ?? 0),
    true,
  ); // NOTE: seconds

  const markers = useAtomValue(ffwdMarkersAtom);
  const markersVisible = useAtomValue(ffwdMarkersVisibleAtom);

  const breaks: SeekbarBreak[] = useMemo(() => {
    if (!seekbarProgress || !markers || !markersVisible) {
      return [];
    }

    if (!currentStream || !("linearStartTime" in currentStream) || !duration)
      return [];

    return markers
      .map((marker) => {
        const startsAtPercentage = getLiveProgress(
          currentStream.linearStartTime,
          addSeconds(currentStream.linearStartTime, duration),
          marker.start,
        );
        const endsAtPercentage = getLiveProgress(
          currentStream.linearStartTime,
          addSeconds(currentStream.linearStartTime, duration),
          marker.end,
        );

        if (!startsAtPercentage || !endsAtPercentage) {
          return null;
        }

        return {
          startsAtPercentage,
          lengthInPercentage: endsAtPercentage - startsAtPercentage,
          kind: "normal" as const,
        };
      })
      .filter(isDefined);
  }, [seekbarProgress, markers, markersVisible, currentStream, duration]);

  return {
    progress,
    currentTime,
    durationLeft, // NOTE: formatted to string H:mm:ss
    liveProgress: 100,
    elapsed: null,
    replayProgress: progress, // NOTE: percentage
    isSeeking: seekbarProgress?.isSeeking || false,
    seek: {
      forward,
      backward,
      confirm,
      toPercentage,
    },
    breaks: [
      ...breaks,
      ...(startPercentage || endPercentage
        ? [
            startPercentage
              ? {
                  kind: "recording" as const,
                  startsAtPercentage: cleanPercentage(startPercentage),
                }
              : null,
            endPercentage
              ? {
                  kind: "recording" as const,
                  startsAtPercentage: cleanPercentage(endPercentage),
                }
              : null,
          ].filter(isDefined)
        : []),
    ],
    progressWithoutSeek,
    durationLeftWithoutSeek,
    currentTimeWithoutSeek,
    elapsedWithoutSeek: currentTimeWithoutSeek,
  };
}

function getProgress(
  time: Nullable<number>,
  duration: Nullable<number>,
): number {
  return time && duration ? (time / duration) * 100 : 0;
}

function cleanPercentage(percentage: number): number {
  return percentage - (percentage % 0.125);
}
