import type { QueryClient } from "@tanstack/query-core";
import { atom } from "jotai";
import { atomWithQuery } from "jotai-tanstack-query";
import type { Translations } from "translations";

import type { Language } from "@sunrise/backend-types-core";
import { currentLanguageAtom } from "@sunrise/i18n";
import type { Store } from "@sunrise/store";

import { loadTranslation } from "./translation-service";
import type { GetTranslationFileFn } from "./translator.types";

export const getTranslationFileAtom = atom<{ fn: GetTranslationFileFn }>({
  fn: () => Promise.resolve([]),
});

const queryKeys = {
  translation: (currentLanguage: Language) =>
    ["translation", currentLanguage] as const,
};

export const translationQueryAtom = atomWithQuery<
  Translations,
  unknown,
  Translations,
  Translations,
  ReturnType<typeof queryKeys.translation>
>((get) => ({
  queryKey: queryKeys.translation(get(currentLanguageAtom)),
  queryFn: async ({ queryKey: [, language] }) => {
    return loadTranslation(language, get(getTranslationFileAtom).fn);
  },
  staleTime: Infinity,
  cacheTime: Infinity,
}));

export const hydrateTranslationQuery = async (
  store: Store,
  queryClient: QueryClient,
) => {
  const language = store.get(currentLanguageAtom);
  await queryClient.prefetchQuery({
    queryKey: queryKeys.translation(language),
    queryFn: async () => {
      return await loadTranslation(
        language,
        store.get(getTranslationFileAtom).fn,
      );
    },
  });
};
