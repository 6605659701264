import { selectAtom, unwrap } from "jotai/utils";
import { atomWithSuspenseQuery } from "jotai-tanstack-query";
import { isNil } from "lodash";

import {
  endpoints,
  queryKeys,
  type User,
  type UserActiveChannelReplay,
} from "@sunrise/backend-types";
import type { ChannelId } from "@sunrise/backend-types-core";
import { hostsAtom, httpClientAtom } from "@sunrise/http-client";
import { selectJwtUserToken } from "@sunrise/jwt";
import { type Nullable } from "@sunrise/utils";

import { ME_ENDPOINT_STALE_TIME_IN_MS } from "./constants";

export const userAtom = atomWithSuspenseQuery<
  Nullable<User>,
  unknown,
  Nullable<User>,
  ReturnType<typeof queryKeys.user>
>((get) => {
  const hosts = get(hostsAtom);
  const host = hosts.api;
  if (isNil(host)) throw new Error("api host is not defined");

  const privateApi = get(httpClientAtom).privateApi;
  if (isNil(privateApi)) throw new Error("privateApi is not defined");

  return {
    // NOTE: We keep the accessToken in the key because we need the data to reload when the access token changes.
    queryKey: queryKeys.user(get(selectJwtUserToken)),
    queryFn: async () => {
      return (await privateApi.get<User>(endpoints.me(host))).data;
    },
    staleTime: ME_ENDPOINT_STALE_TIME_IN_MS, // 5 hours stale time
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  };
});

export const userIdAtom = selectAtom(
  unwrap(userAtom),
  (state) => state?.data?.id,
);

export const userFullNameAtom = selectAtom(unwrap(userAtom), (state) =>
  `${state?.data?.first_name ?? ""} ${state?.data?.last_name ?? ""}`.trim(),
);

export const userEmailAtom = selectAtom(
  unwrap(userAtom),
  (state) => state?.data?.email,
);

export const userPlanAtom = selectAtom(
  unwrap(userAtom),
  (state) => state?.data?.account?.plan,
);

export function selectStartUpChannelId(state: User): Nullable<ChannelId> {
  return state.startup_channel.channel_id;
}

export function selectActiveReplayChannels(
  state: User,
): Nullable<UserActiveChannelReplay[]> {
  return state.global_client_settings.replay_activated_channels;
}
