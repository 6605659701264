import type { Language } from "@sunrise/backend-types-core";
import { atomWithStorageWithDefault, isSSR } from "@sunrise/utils";

import { getBrowserLanguage } from "./get-browser-language";

/**
 * Stores the current language of the application.
 * atomWithStorageWithDefault because it needs to be instantly correct
 */
export const currentLanguageAtom = atomWithStorageWithDefault<Language>(
  "current-language",
  getBrowserLanguage(isSSR() ? "de" : window.navigator.language), // if window is undefined, means we're running on a server
);
