import { atom } from "jotai";
import { atomWithReducer, selectAtom } from "jotai/utils";
import { atomEffect } from "jotai-effect";
import { isNil } from "lodash";
import md5 from "md5";

import type { UserId, UserToken } from "@sunrise/backend-types-core";
import { hasLocalStorage, isSSR, type Nullable } from "@sunrise/utils";

import { type JWTPayload } from "./jwt.types";
import { decodeJwt } from "./jwt.utils";

const WS_TOKEN_KEY = "yallo:ws_token";
const ACCESS_TOKEN_KEY = "yallo:access_token";
const REFRESH_TOKEN_KEY = "yallo:refresh_token";
const FEATURE_SET_KEY = "yallo:feature_set";

export type JWTAtomState = {
  accessToken: Nullable<string>;
  refreshToken: Nullable<string>;
  wsToken: Nullable<string>;
  decodedPayload: Nullable<JWTPayload>;
};

export function makeJWTAtomDefaultState(): JWTAtomState {
  return {
    accessToken: null,
    refreshToken: null,
    wsToken: null,
    decodedPayload: undefined,
  };
}

type ActionSetTokens = {
  type: "jwt/set-tokens";
  payload: {
    accessToken: string;
    refreshToken: string;
    wsToken: string | null;
  };
};

type ActionSetDecodedPayload = {
  type: "jwt/set-decoded-payload";
  payload: JWTPayload;
};

type ActionClear = {
  type: "jwt/clear";
};

/**
 * Action for testing purpose **only**
 */
type ActionTestSetState = {
  type: "jwt/test/set-state";
  payload: Partial<JWTAtomState>;
};

type JWTAction =
  | ActionSetTokens
  | ActionSetDecodedPayload
  | ActionClear
  | ActionTestSetState;

export function jwtAtomReducer(
  state: JWTAtomState,
  action: JWTAction,
): JWTAtomState {
  switch (action.type) {
    case "jwt/set-tokens": {
      return {
        ...state,
        ...action.payload,
      };
    }
    case "jwt/set-decoded-payload": {
      return {
        ...state,
        decodedPayload: action.payload,
      };
    }
    case "jwt/clear": {
      return makeJWTAtomDefaultState();
    }
    case "jwt/test/set-state": {
      return {
        ...state,
        ...action.payload,
      };
    }
  }
}

const _jwtAtom = atomWithReducer<JWTAtomState, JWTAction>(
  isSSR()
    ? makeJWTAtomDefaultState()
    : {
        accessToken: localStorage.getItem(ACCESS_TOKEN_KEY),
        refreshToken: localStorage.getItem(REFRESH_TOKEN_KEY),
        wsToken: localStorage.getItem(WS_TOKEN_KEY),
        decodedPayload: JSON.parse(
          localStorage.getItem(FEATURE_SET_KEY) ?? "null",
        ),
      },
  jwtAtomReducer,
);

_jwtAtom.debugLabel = "_jwtAtom";

export const jwtAtom = atom<JWTAtomState, [JWTAction], void>(
  (get) => {
    get(syncStorageEffect);
    get(setDecodedPayloadEffect);

    return get(_jwtAtom);
  },
  (_get, set, payload) => {
    set(_jwtAtom, payload);
  },
);

jwtAtom.debugLabel = "jwtAtom";

/*
 *
 * EFFECTS
 *
 */

const syncStorageEffect = atomEffect((get) => {
  if (!hasLocalStorage()) {
    return;
  }

  const { accessToken, refreshToken, wsToken, decodedPayload } = get(_jwtAtom);

  if (accessToken) localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
  else localStorage.removeItem(ACCESS_TOKEN_KEY);

  if (refreshToken) localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
  else localStorage.removeItem(REFRESH_TOKEN_KEY);

  if (wsToken) localStorage.setItem(WS_TOKEN_KEY, wsToken);
  else localStorage.removeItem(WS_TOKEN_KEY);

  if (decodedPayload) {
    localStorage.setItem(FEATURE_SET_KEY, JSON.stringify(decodedPayload));
  } else localStorage.removeItem(FEATURE_SET_KEY);
});

const setDecodedPayloadEffect = atomEffect((get, set) => {
  const { accessToken } = get(_jwtAtom);
  if (isNil(accessToken)) return;

  try {
    const decodedPayload = decodeJwt(accessToken);
    set(_jwtAtom, actionJWTSetDecodedPayload(decodedPayload));
    // eslint-disable-next-line no-empty
  } catch {}
});

/*
 *
 * Actions
 *
 */

export function actionJWTSetTokens(payload: {
  refreshToken: string;
  accessToken: string;
  wsToken: string | null;
}): ActionSetTokens {
  return {
    type: "jwt/set-tokens",
    payload,
  };
}

export function actionJWTSetDecodedPayload(
  decodedPayload: JWTPayload,
): ActionSetDecodedPayload {
  return {
    type: "jwt/set-decoded-payload",
    payload: decodedPayload,
  };
}

export function actionJWTClear(): ActionClear {
  return {
    type: "jwt/clear",
  };
}

/*
 *
 * Selectors
 *
 */

export const selectIsLoggedIn = selectAtom(jwtAtom, (s) => {
  return !!(s.accessToken && s.refreshToken && s.decodedPayload);
});

function selectAccessTokenFn(s: JWTAtomState): JWTAtomState["accessToken"] {
  return s.accessToken;
}

export const selectAccessToken = selectAtom(jwtAtom, selectAccessTokenFn);

export const selectJwtUserToken = selectAtom<JWTAtomState, UserToken | null>(
  jwtAtom,
  (jwt) =>
    (jwt?.decodedPayload?.refresh_token_hash as UserToken) ??
    (jwt?.accessToken ? md5(jwt.accessToken) : null),
);

export function selectRefreshToken(
  s: JWTAtomState,
): JWTAtomState["refreshToken"] {
  return s.refreshToken;
}

export function selectDecodedPayload(
  s: JWTAtomState,
): JWTAtomState["decodedPayload"] {
  return s.decodedPayload;
}

export function selectPlanUpgradeAvailable(s: JWTAtomState): boolean {
  return s.decodedPayload?.feature_set.features.plan_upgrade_available ?? false;
}

export const selectPlanUpgradeAvailableAtom = selectAtom(
  jwtAtom,
  selectPlanUpgradeAvailable,
);

export const featureSetAtom = selectAtom(
  jwtAtom,
  (s) => s.decodedPayload?.feature_set.features,
);

export const selectLiveStreamingTimeLimitAtom = selectAtom(jwtAtom, (s) => {
  return s.decodedPayload?.feature_set.features.live_streaming_time_limit;
});

export const selectCanLogin = selectAtom(jwtAtom, (s) => {
  return s.decodedPayload?.feature_set.features.can_login ?? true;
});

export const selectCanLogout = selectAtom(jwtAtom, (s) => {
  return s.decodedPayload?.feature_set.features.can_logout;
});

/**
 * Returns the User ID if present inside the decoded JWT payload.
 */
export const selectCurrentUserId = selectAtom(jwtAtom, (s) => {
  return (
    (s.decodedPayload?.user_id as UserId) ??
    (s.decodedPayload?.user?.id as UserId) ??
    null
  );
});

export const selectCanRecord = selectAtom(jwtAtom, (s) => {
  return s.decodedPayload?.feature_set.features.can_record ?? false;
});

export const selectCanReplay = selectAtom(jwtAtom, (s) => {
  return s.decodedPayload?.feature_set.features.can_replay ?? false;
});

export const selectCanDeleteAccount = selectAtom(jwtAtom, (s) => {
  return s.decodedPayload?.feature_set.features.can_delete_account ?? false;
});

export const selectCanRecordInPast = selectAtom(jwtAtom, (s) => {
  return s.decodedPayload?.feature_set.features.can_record_in_past ?? false;
});

export const selectCanRecordSeries = selectAtom(jwtAtom, (s) => {
  return s.decodedPayload?.feature_set.features.can_record_type_asset ?? false;
});

export const selectWsToken = selectAtom(jwtAtom, (s) => s.wsToken);

export const selectCanUpgrade = selectAtom(jwtAtom, (s) => {
  return s.decodedPayload?.feature_set.features.plan_upgrade_available;
});

export const selectStreamTypeAsString = selectAtom(jwtAtom, (s) => {
  return s.decodedPayload?.feature_set.features.stream_type ?? null;
});

export const selectShowBrandingAds = selectAtom(jwtAtom, (s) => {
  return s.decodedPayload?.feature_set.features.show_branding_day_ads ?? false;
});
