import { atomFamily } from "jotai/utils";
import { atomWithSuspenseQuery } from "jotai-tanstack-query";
import { isNil } from "lodash";

import type { MappedAsset } from "@sunrise/asset";
import { ngAssetApiAtom } from "@sunrise/backend-ng-asset";
import { validate400and404status } from "@sunrise/backend-ng-core";
import { queryKeys } from "@sunrise/backend-types";
import type { AssetId } from "@sunrise/backend-types-core";
import { currentLanguageAtom } from "@sunrise/i18n";
import type { Nullable } from "@sunrise/utils";

import { mapBackendAssetDetailsNg } from "./map-backend-asset-details.ng";

export const assetDetailsByIdNgAtom = atomFamily((assetId: Nullable<AssetId>) =>
  atomWithSuspenseQuery<Nullable<MappedAsset>>((get) => {
    const ngApi = get(ngAssetApiAtom);
    const language = get(currentLanguageAtom);

    return {
      enabled: !isNil(assetId),
      queryKey: queryKeys.assetById(assetId, language),
      queryFn: async () => {
        if (!assetId) return null;
        const asset = await ngApi.asset.assetDetailAssetV1AssetsAssetIdGet(
          assetId,
          {
            validateStatus: validate400and404status,
          },
        );
        return mapBackendAssetDetailsNg(asset.data);
      },
      staleTime: Infinity,
      cacheTime: Infinity,
    };
  }),
);
