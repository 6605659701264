import { featureAtom } from "@sunrise/feature-flags";

type OddReqFeature = {
  /**
   * Alter means inject the header when sus. Suppress means the same as the whole thing being null.
   */
  mode: "alter" | "suppress";
  value: number;
  // NOTE: We do not support this anymore on NG.
  verify: false;
};

/**
 * This is the existing flag from "winston" which we will just re-use.
 */
export const oddReqFeatureAtom = featureAtom<OddReqFeature | null>(
  "odd-req",
  null,
  {
    description: "Odd request feature",
    // Some modes to cycle through (so you don't have to construct the new flag in the feature flag UI).
    // - null: No tracking.
    // - { value: 140, mode: "alter", verify: false }: Alter the request if it errors faster than 140ms (except for timeouts and of course empty errors).
    // - { value: 140, mode: "suppress", verify: false }: No tracking.
    values: [null, { value: 140, mode: "alter", verify: false }],
  },
);
