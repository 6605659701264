import { atom } from "jotai";
import { atomFamily } from "jotai/utils";

import { isLegacyBackendAtom } from "@sunrise/backend-core";
import { recordingStatusByEpgEntryIdQueryAtom } from "@sunrise/backend-ng-recordings";
import type { EPGEntryId } from "@sunrise/backend-types-core";
import { selectCanRecord } from "@sunrise/jwt";
import { isNil } from "@sunrise/utils";

import { recordingByRecordingIdAtom } from "./recording-by-recording-id.atom";
import { recordingStatusByEpgIdAtom } from "./recording-status-by-epg-id.atom";
import { recordingStatusSocketAtom } from "./recording-status-socket.atom";

/**
 * Returns the full recording data for a specific EPGEntryId.
 *
 * It's directly pulling the recording data for the backend.
 * So it does not require us to load all the recording data for every page to find the correct recording details.
 *
 * It does so by first looking up if there is a recording id for the passed epgId through the recording-status endpoint.
 * Then when a recordingId is found we will ask the details of that specific recordingId to the backend.
 *
 * @deprecated
 *   We should no longer be using this for NG-based apps.
 *   For these apps we should have details pages dedicated for recordings and they should be loaded by recording id.
 *
 *   Recordings actions on those can then also automatically assume we already have a recording etc.
 */
export const recordingByEpgIdAtom = atomFamily((epgId: EPGEntryId) => {
  const innerAtom = atom(async (get) => {
    if (get(isLegacyBackendAtom)) {
      // First translate EPGEntryId to recordingId.
      // NOTE: If there can be multiple recordings for the same epgId, this for now just returns the first one.
      const status = await get(recordingStatusByEpgIdAtom(epgId));
      if (isNil(status) || !status?.recordingId) {
        return null;
      }

      return get(recordingByRecordingIdAtom(status.recordingId));
    } else {
      if (!get(selectCanRecord)) return null;

      // First we look if we have a status directly by epgEntryId.
      // If we do we don't need to ask for the status in the API. We can assume that data on this level is always up to date.
      const socketStates = get(recordingStatusSocketAtom);
      const socketStateByEpgEntryId = socketStates.find(
        (status) => status.epgId === epgId,
      );

      // When we have one it means we know the recordingId and we can look up the related recording details.
      if (socketStateByEpgEntryId) {
        return get(
          recordingByRecordingIdAtom(socketStateByEpgEntryId.recordingId),
        );
      }

      const status = await get(recordingStatusByEpgEntryIdQueryAtom(epgId));
      if (!status.data) {
        return null;
      }

      return get(recordingByRecordingIdAtom(status.data.recordingId));
    }
  });
  innerAtom.debugLabel = `recordingByEpgIdAtom(${epgId})`;
  return innerAtom;
});
