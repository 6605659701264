import type { ChannelId, RecordingId } from "@sunrise/backend-types-core";
import { BaseError } from "@sunrise/error";

type ReasonMissingChannelInfo = {
  type: "missing-channel-info";
  channelId: ChannelId;
};

type ReasonMissingStreamUrl = {
  type: "missing-stream-url";
  channelId: ChannelId;
};

type ReasonMissingRecordingId = {
  type: "recording_id_missing";
  recordingId: RecordingId;
};

type ReasonMissingRecordingStreamUrl = {
  type: "recording_missing_stream_url";
  recordingId: RecordingId;
};

type ReasonMissingProviderRecordingId = {
  type: "recording_missing_provider_recording_id";
  recordingId: RecordingId;
};

type Reason =
  | ReasonMissingChannelInfo
  | ReasonMissingStreamUrl
  | ReasonMissingRecordingId
  | ReasonMissingRecordingStreamUrl
  | ReasonMissingProviderRecordingId;

export class StreamNotFoundError extends BaseError {
  constructor(public readonly reason: Reason) {
    super(`StreamNotFoundError`, reason.type, {
      errorCode: reason.type,
    });
  }

  override get errorType(): "stream_error" {
    return "stream_error";
  }
}
