import type { Getter } from "jotai";

import type { UpsellLinkContext } from "@sunrise/backend-ng-upsell";
import { type Translatable } from "@sunrise/i18n";
import type { Nullable } from "@sunrise/utils";

import { DIALOG_BUTTONS } from "./dialog-buttons";
import { ERROR_MAPPING } from "./error-mapping";
import { generalErrorDisplayContentsFeatureAtom } from "./general-error-display-contents.feature.atom";
import { getErrorMapping } from "./get-error-mapping";
import { getErrorMessageAndDescription } from "./helpers/get-error-message-and-description";
import type { DialogButtonType, ErrorConfiguration } from "./types";

export type ErrorDialogConfiguration = {
  title: Translatable;
  backBehaviour?: "close-modal" | "kill-app" | "blocked";
  description: Translatable;
  confirmationButton?: DialogButtonType;
  confirmationLabel?: Translatable;
  onConfirm?: () => void;
  rejectionButton?: DialogButtonType;
  rejectionLabel?: Translatable;
  onReject?: () => void;
  focusReject?: boolean;
  id: string;
  isGeneralError: boolean;
  upsell?: UpsellLinkContext;
  technicalErrorName: string;
};

export type GetErrorButtonFn = (code?: DialogButtonType) => () => void;

export async function getErrorDialogConfiguration(
  error: Error,
  getButtonFn: GetErrorButtonFn,
  getter: Getter,
  options: {
    id?: string;
  } = {},
): Promise<Nullable<ErrorDialogConfiguration>> {
  let configuration = getErrorMapping(error);

  if (!configuration) {
    const data = getErrorMessageAndDescription(
      error,
      getter(generalErrorDisplayContentsFeatureAtom),
    );

    if (data) {
      configuration = {
        ...ERROR_MAPPING.GENERAL_ERROR,
        description: data.description,
        title: data.title,
      };
    }
  }

  if (!configuration) {
    return null;
  }

  const createConfigurationValue = (
    config: ErrorConfiguration,
  ): ErrorDialogConfiguration => {
    const hasConfirmation = config.type !== "blocking";

    return {
      title: config.title,
      description: config.description,
      confirmationButton: hasConfirmation
        ? config.confirmationButton
        : undefined,
      confirmationLabel: hasConfirmation
        ? {
            key: DIALOG_BUTTONS[config.confirmationButton].label,
          }
        : undefined,
      onConfirm: hasConfirmation
        ? getButtonFn(config.confirmationButton)
        : undefined,
      rejectionButton: config.rejectionButton,
      rejectionLabel: config.rejectionButton
        ? { key: DIALOG_BUTTONS[config.rejectionButton].label }
        : undefined,
      onReject: config.rejectionButton
        ? getButtonFn(config.rejectionButton)
        : undefined,
      focusReject: config.focusReject,
      backBehaviour: config.type === "blocking" ? "kill-app" : "close-modal",
      id: options.id ?? "general-error",
      isGeneralError: configuration === ERROR_MAPPING.GENERAL_ERROR,
      upsell: config.upsell,
      technicalErrorName: config.technicalErrorName,
    };
  };

  if (typeof configuration === "function") {
    const dynamicConfig = { ...(await configuration(getter)) };

    return createConfigurationValue(dynamicConfig);
  }

  return createConfigurationValue(configuration);
}
