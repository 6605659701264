import type { BaseErrorOptions } from "@sunrise/error";
import { BaseError } from "@sunrise/error";

export class StreamFailedToLoadError extends BaseError {
  constructor(message: string, opts?: BaseErrorOptions) {
    super("StreamFailedToLoadError", message, opts);
  }

  override get errorType(): "stream_error" {
    return "stream_error";
  }
}
