import { atom } from "jotai";

export const buildDetailsAtom = atom<{
  buildNumber: string | null;
  buildDate: string | null;
  commitHash: string | null;
}>({
  buildNumber: null,
  buildDate: null,
  commitHash: null,
});
