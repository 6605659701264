/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

/** BaseError[StreamCheckError] */
export interface BaseErrorStreamCheckError {
  detail: StreamCheckError;
}

/** DownloadVideoResolutions */
export enum DownloadVideoResolutions {
  Value288P = "288p",
  Value432P = "432p",
  Value576P = "576p",
  Value720P = "720p",
  Value1080P = "1080p",
}

/** HTTPValidationError */
export interface HTTPValidationError {
  /** Detail */
  detail?: ValidationError[];
}

/** StreamCheckError */
export interface StreamCheckError {
  message: StreamCheckErrorMessages;
  /** Check */
  check: string;
}

/** StreamCheckErrorMessages */
export enum StreamCheckErrorMessages {
  E_CANNOT_REPLAY = "E_CANNOT_REPLAY",
  E_CANNOT_RECORD = "E_CANNOT_RECORD",
  E_START_NOT_IN_TIME_WINDOW = "E_START_NOT_IN_TIME_WINDOW",
  E_REPLAY_NOT_ACTIVATED = "E_REPLAY_NOT_ACTIVATED",
  E_TOO_MANY_STREAMS = "E_TOO_MANY_STREAMS",
  E_IS_ABROAD = "E_IS_ABROAD",
  E_NOT_IN_CHANNEL_PACKAGE = "E_NOT_IN_CHANNEL_PACKAGE",
}

/** StreamSchema */
export interface StreamSchema {
  /**
   * Stream Provider Name
   * The name of the stream provider
   */
  provider_name: string;
  /**
   * Stream URL
   * The URL of the stream
   */
  stream_url: string;
  /**
   * License URL
   * The URL of the license for the stream
   */
  license_url?: string | null;
  /**
   * GT-12 Schedule Object
   * The GT-12 schedule object for the stream
   */
  gt12_schedule?: ZattooGT12Schedule[] | null;
}

/** ValidLanguages */
export enum ValidLanguages {
  En = "en",
  De = "de",
  Fr = "fr",
  It = "it",
}

/** ValidationError */
export interface ValidationError {
  /** Location */
  loc: (string | number)[];
  /** Message */
  msg: string;
  /** Error Type */
  type: string;
}

/** ZattooAdBreak */
export interface ZattooAdBreak {
  /**
   * Start
   * Beginning of the ad break
   */
  start: number;
  /**
   * Ads End
   * End of ad break
   */
  ads_end?: number | null;
  /**
   * Promo Start
   * Beginning of channel promotion
   */
  promo_start?: number | null;
  /**
   * End
   * End of channel promotion (or ad break if channel promotion is missing)
   */
  end: number;
}

/**
 * ZattooGT12Schedule
 * Objects / fields documentation comes from the Zattoo API docs.
 */
export interface ZattooGT12Schedule {
  /**
   * Program Start
   * Beginning of the program
   */
  program_start: number;
  /** Ad Breaks */
  ad_breaks: ZattooAdBreak[] | null;
}

import { HttpClient, RequestParams } from "@sunrise/backend-ng-core";
import type { TimeISOString } from "@sunrise/backend-types-core";

/**
 * @title FastAPI
 * @version 0.1.0
 */
export class GeneratedApi {
  http: HttpClient;

  constructor(http: HttpClient) {
    this.http = http;
  }

  stream = {
    /**
     * @description This endpoint returns a `live` or `replay` stream for the given channel. ## Live Stream Simple request no additional query parameters are required. ## Replay Stream The request should contain `start` and `end`. If only `start` is provided, the BE will calculate the `end`. The response can contain a GT-12 schedule, if the channels supports it.
     *
     * @tags channels
     * @name GetChannelStreamStreamV1ChannelsChannelIdStreamGet
     * @summary Get Channel Stream
     * @request GET:/stream/v1/channels/{channel_id}/stream
     * @secure
     */
    getChannelStreamStreamV1ChannelsChannelIdStreamGet: (
      channelId: string,
      query?: {
        /** Start */
        start?: TimeISOString | null;
        /** End */
        end?: TimeISOString | null;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<StreamSchema, BaseErrorStreamCheckError | HTTPValidationError>({
        path: `/stream/v1/channels/${channelId}/stream`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description This endpoint returns a recording stream for the given recording. The response can contain a GT-12 schedule, if the recording is in the replay window and the channel supports it. ***IMPORTANT**: The `provider_recording_id` is not our mongo id for the recording, but the one from the stream provider.*
     *
     * @tags recordings
     * @name GetRecordingStreamStreamV1RecordingsProviderRecordingIdStreamGet
     * @summary Get Recording Stream
     * @request GET:/stream/v1/recordings/{provider_recording_id}/stream
     * @secure
     */
    getRecordingStreamStreamV1RecordingsProviderRecordingIdStreamGet: (
      providerRecordingId: string,
      params: RequestParams = {},
    ) =>
      this.http.request<StreamSchema, BaseErrorStreamCheckError | HTTPValidationError>({
        path: `/stream/v1/recordings/${providerRecordingId}/stream`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description This endpoint redirects the download request of the recording, to start the download. ***IMPORTANT**: The `provider_recording_id` is not our mongo id for the recording, but the one from the stream provider.*
     *
     * @tags recordings
     * @name DownloadRecordingStreamV1RecordingsProviderRecordingIdDownloadGet
     * @summary Download Recording
     * @request GET:/stream/v1/recordings/{provider_recording_id}/download
     * @secure
     */
    downloadRecordingStreamV1RecordingsProviderRecordingIdDownloadGet: (
      providerRecordingId: string,
      query?: {
        /**
         * Video Resolution
         * @default "720p"
         */
        video_resolution?: DownloadVideoResolutions | null;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<any, void | BaseErrorStreamCheckError | HTTPValidationError>({
        path: `/stream/v1/recordings/${providerRecordingId}/download`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description This endpoint builds the vod stream request and redirects to the actual vod stream.
     *
     * @tags vods
     * @name GetVodStreamStreamV1VodsVodIdStreamGet
     * @summary Get Vod Stream
     * @request GET:/stream/v1/vods/{vod_id}/stream
     * @secure
     */
    getVodStreamStreamV1VodsVodIdStreamGet: (vodId: string, params: RequestParams = {}) =>
      this.http.request<any, BaseErrorStreamCheckError | HTTPValidationError>({
        path: `/stream/v1/vods/${vodId}/stream`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name HealthStreamV1HealthGet
     * @summary Health
     * @request GET:/stream/v1/health
     */
    healthStreamV1HealthGet: (params: RequestParams = {}) =>
      this.http.request<any, any>({
        path: `/stream/v1/health`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name RootStreamV1Get
     * @summary Root
     * @request GET:/stream/v1
     */
    rootStreamV1Get: (params: RequestParams = {}) =>
      this.http.request<any, any>({
        path: `/stream/v1`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
}
