import { atom } from "jotai";

import { isLegacyBackendAtom } from "@sunrise/backend-core";

import { ngClientSideReplayChecksEnabledFeatureAtom } from "../features/ng-client-side-replay-checks-enabled.feature.atom";

/**
 * For legacy backends, we always use client-side checks.
 * For the NG backend, we said we were not going to be relying on client-side checks anymore.
 * But we are still using them for the replay window checks.
 * This atom is used to determine whether we should use client-side checks or not.
 */
export const areClientSideReplayChecksEnabledAtom = atom((get) => {
  if (get(isLegacyBackendAtom)) {
    return true;
  }

  return get(ngClientSideReplayChecksEnabledFeatureAtom);
});
