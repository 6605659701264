import { featureAtom } from "@sunrise/feature-flags";

/**
 * Do not rely on this atom directly. Use the `areClientSideReplayChecksEnabledAtom` instead.
 */
export const ngClientSideReplayChecksEnabledFeatureAtom = featureAtom(
  "ng-client-side-replay-checks-enabled",
  false,
  {
    description:
      "When set to true, we will perform client-side checks for replay windows. When set to false, we will perform server-side checks only for replay. This flag is only relevant for NG.",
  },
);
