import type { google as googleNS } from "@alugha/ima";

/**
 * Get the type and code of an error.
 * The type determines how to report the error.
 *
 * @param err - The error object.
 * @param vastTimeoutCutoffTime - The cutoff time for VAST timeout.
 * @param errorAt - The cutoff time for other errors.
 * @param ima - The IMA namespace.
 * @returns The type and code of the error.
 */
export function getErrorTypeAndCode(
  err: googleNS.ima.AdError,
  vastTimeoutCutoffTime: number | null = null,
  errorCutoffTime: number | null = null,
  ima?: typeof googleNS.ima,
  now: number = Date.now(),
): {
  type: "empty" | "error" | "track";
  errorCode: number;
} {
  let type: "empty" | "error" | "track" = "error";

  const errorCode = err.getErrorCode();
  const EMPTY_ERRORS = [
    ima?.AdError.ErrorCode.VAST_EMPTY_RESPONSE,
    ima?.AdError.ErrorCode.VAST_NO_ADS_AFTER_WRAPPER,
  ];
  const isVastEmptyError = ima && EMPTY_ERRORS.includes(err.getErrorCode());
  if (isVastEmptyError) {
    type = "empty";
  }

  // Determine if the error is a timeout, and if so, whether it's suspicious due to timing out before it could have timed out at the earliest.

  // When the error times out before the cutoff time, it's suspicious.
  const isTimedOut =
    vastTimeoutCutoffTime &&
    errorCode === ima?.AdError.ErrorCode.VAST_LOAD_TIMEOUT
      ? now < vastTimeoutCutoffTime
      : false;

  // When the error occurs before the cutoff time, it's suspicious.
  const isFastFailure = !!errorCutoffTime && now < errorCutoffTime;

  const isSuspicious = !isVastEmptyError && (isTimedOut || isFastFailure);

  return {
    type: isSuspicious ? "track" : type,
    errorCode,
  };
}
